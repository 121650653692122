import React from "react";

import { motion } from "framer-motion";

function AnimatedLogo1() {
  const transition = {
    duration: 2,
    ease: [0.4, 0.82, 0.165, 1],
  };

  const initial = { pathLength: 0, stroke: "#013", strokeWidth: 0, opacity: 0 };
  const animate = {
    pathLength: 1.2,
    stroke: "#0cf",
    strokeWidth: 2,
    opacity: 1,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 50 50"
      width={window.innerWidth < 900 ? "25%" : "189.75"}
      height={"100%"}
    >
      <motion.circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="18"
        cy="20"
        r="15"
        transform="translate(0 3.268)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="2"
        initial={initial}
        animate={animate}
        transition={{ ...transition, delay: 1.2 }}
      />
      <motion.circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="18"
        cy="20"
        r="15"
        transform="translate(14.711 5.419)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="2"
        initial={initial}
        animate={animate}
        transition={{ ...transition, delay: 0.8 }}
      />
      <motion.circle
        id="Ellipse_5"
        data-name="Ellipse 5"
        cx="18"
        cy="20"
        r="15"
        transform="translate(7.029 0)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="2"
        initial={initial}
        animate={animate}
        transition={{ ...transition, delay: 0.4 }}
      />
      <motion.circle
        id="Ellipse_6"
        data-name="Ellipse 6"
        cx="18"
        cy="20"
        r="15"
        transform="translate(7.058 9.771)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="2"
        initial={initial}
        animate={animate}
        transition={{ ...transition, delay: 1.6 }}
      />
    </svg>
  );
}

export default AnimatedLogo1;
