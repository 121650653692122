import React from "react";

import Background from "../../assets/SolarOffsetBG.png";

function MaximizeSolarOffset() {
  return (
    <div className="pageWrapper">
      <div className="pageContent" style={styles.content}>
        <div className="card">
          <div>
            <h2>Maximize</h2>
            <h1>solar offset</h1>
          </div>
          <p style={styles.text}>
            Maximizing solar off set means greater savings because more of the
            power you and your family consume in the home is generated through
            clean, price-protected power.
          </p>
        </div>
      </div>
      <img src={Background} alt="Solar Offset Background" style={styles.img} />
    </div>
  );
}

export default MaximizeSolarOffset;

const styles = {
  content: {
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    background: "white",
    width: "40%",
    marginTop: "0px",
  },
  text: {
    marginTop: "1em",
  },
  img: {
    position: "absolute",
    height: "100%",
    transform: "translate(-9%)",
    zIndex: 0,
  },
};
