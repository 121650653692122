import React from "react";

import Pages from "./Pages";

import "../App.css";

import { motion } from "framer-motion";

function Book({
  children,
  currentPage,
  goingForward,
  perspective,
  handlePageTurn,
}) {
  const variants = {
    open: { x: "0%" },
    frontClosed: { x: "-25%" },
    backClosed: { x: "25%" },
  };

  const buildPages = () => {
    const result = [];
    for (let i = 0; i < children.length; i += 2) {
      const pageNumber = i / 2;
      result.push(
        <Pages
          isFlipped={pageNumber < currentPage}
          frontComponent={children[i]}
          backComponent={children[i + 1]}
          key={pageNumber}
          pageNumber={pageNumber}
          goingForward={goingForward}
          currentPage={currentPage}
          perspective={perspective}
          handlePageTurn={handlePageTurn}
        />
      );
    }
    return result;
  };
  const pages = buildPages();

  return (
    <motion.div
      className="book"
      variants={variants}
      initial="frontClosed"
      animate={
        currentPage < 1
          ? "frontClosed"
          : currentPage > Math.floor((children.length - 1) / 2)
          ? "backClosed"
          : "open"
      }
      transition={{ ease: "easeInOut" }}
    >
      {pages.reverse()}
    </motion.div>
  );
}

export default Book;
