import React from "react";

function BenefitsOfSolar() {
  return (
    <div className="pageWrapper">
      <div className="pageContent" style={styles.content}>
        <div style={styles.header}>
          <h2>Benefits of</h2>
          <h1>solar</h1>
        </div>

        <div style={styles.itemWrapper}>
          <h4>maximize</h4>
          <h3>solar offset</h3>
          <p style={styles.text}>
            We strive to help you maximize your solar power and savings.
          </p>
        </div>

        <div style={styles.itemWrapper}>
          <h4>customized</h4>
          <h3>design & engineering</h3>
          <p style={styles.text}>
            We will design and engineer a solar system that is tailored for your
            home.
          </p>
        </div>

        <div style={styles.itemWrapper}>
          <h4>We make home</h4>
          <h3>solar beautiful</h3>
          <p style={styles.text}>
            We rely exclusively on premium components and materials for your
            solar system. Our solar systems are designed and installed to
            enhance the aesthetics of your home.
          </p>
        </div>
      </div>
    </div>
  );
}

export default BenefitsOfSolar;

const styles = {
  content: { display: "flex", flexDirection: "column" },
  header: {
    marginBottom: window.innerWidth > 800 ? "2em" : "1em",
    alignSelf: "flex-start",
  },
  itemWrapper: {
    marginBottom: "1em",
  },
  text: {
    marginTop: "0.5em",
  },
};
