import React from "react";
import { ReactComponent as OfficesAndStates } from "../../assets/OfficesAndStates.svg";

const states = [
  {
    state: "New Jersey",
    cities: ["South Plainfield", "Metuchen", "Cherry Hill", "Wall"],
  },
  {
    state: "New York",
    cities: ["Plainview", "Staten Island"],
  },
  {
    state: "Pennsylvania",
    cities: ["Lancaster"],
  },
  {
    state: "Florida",
    cities: [
      "Orlando",
      "Tampa",
      "Fort Lauderdale",
      "Fort Myers",
      "Jacksonville",
      "Port St Lucie",
    ],
  },
  {
    state: "Texas",
    cities: ["Austin", "San Antonio", "Houston", "El Paso", "Dallas"],
  },
  {
    state: "California",
    cities: ["Orange", "San Francisco", "San Diego"],
  },
  {
    state: "Connecticut",
    cities: ["East Berlin", "Stamford"],
  },
  {
    state: "Massachusetts",
    cities: ["Malborough"],
  },
  {
    state: "Georgia",
    cities: ["Atlanta"],
  },
  {
    state: "Arizona",
    cities: ["Phoenix"],
  },
  {
    state: "Nevada",
    cities: ["Las Vegas"],
  },
];

function GainingMomentum1({ setHoveredCity }) {
  const renderStates = () => {
    return states.map(({ state, cities }, i) => {
      const style = {};
      style.width =
        i % 3 === 0 || (state === "Massachusetts" && window.innerWidth <= 900)
          ? "calc(50% - 2em)"
          : "calc(25%)";
      if (i % 3 === 1) {
        style.margin =
          window.innerWidth > 900
            ? " 0 1rem  1em 1em"
            : " 0 0.5rem  0.5rem 0.5em";
      }
      return (
        <div style={style} key={i}>
          <h3>{state}</h3>
          <div style={styles.citiesWrapper}>
            {cities.map((city, j) => {
              return (
                <p
                  onMouseOver={() => setHoveredCity(city)}
                  onMouseLeave={() => setHoveredCity()}
                  style={
                    cities[j + 1]
                      ? { ...styles.city, ...styles.divider }
                      : styles.city
                  }
                  key={j}
                >
                  {city}
                </p>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="pageWrapper" style={{ background: "#F4F4F4" }}>
      <div className="pageContent" style={styles.content}>
        <div style={{ alignSelf: "flex-start" }}>
          <h2>Gaining</h2>
          <h1>momentum</h1>
        </div>
        <p>
          Momentum Solar has been one of the fastest growing solar companies
          across the country. With offices now in 13 states and a family of over
          2K strong, the momentum keeps on going!
        </p>
        <div style={styles.statesWrapper}>{renderStates()}</div>
        {window.innerWidth > 1200 && window.innerHeight > 590 && (
          <OfficesAndStates style={styles.svg} />
        )}
      </div>
    </div>
  );
}

export default GainingMomentum1;

const styles = {
  content: {
    flexDirection: "column",
    alignItems: "space-between",
    maxHeight: "100%",
  },
  statesWrapper: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginTop: window.innerWidth > 900 ? "1rem" : "0.5rem",
  },

  citiesWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  city: {
    zIndex: 5000,
    cursor: "default",
  },
  divider: {
    borderRight: "solid 1px #52D2F6",
    marginRight: "0.5em",
    paddingRight: "0.5em",
  },
  svg: {
    width: "40%",
    marginBottom: "0.5em",
  },
};
