import React from "react";

import { ReactComponent as PhoneIcon } from "../../assets/PhoneIcon.svg";

import { motion } from "framer-motion";

function ScreenSizeModal({ setOverrideValidSize }) {
  return (
    <div style={{ textAlign: "center" }}>
      <motion.div
        animate={{
          opacity: [0, 1, 1, 1, 0],
          rotateZ: [null, null, 90, 90, 90],
        }}
        transition={{
          repeat: Infinity,
          duration: 2,
          times: [0, 0.2, 0.5, 0.8, 1],
        }}
      >
        <PhoneIcon height={50} width="auto" />
      </motion.div>
      <p style={styles.text}>
        The Momentum Solar Lookbook is best viewed in landscape on smaller
        screens.
      </p>

      <button
        style={{
          border: "none",
          borderRadius: 6,
          padding: "1em 2em",
          background: "#33ccff",
          fontWeight: "bold",
          color: "white",
        }}
        onClick={() => setOverrideValidSize(true)}
      >
        Continue Anyway
      </button>
    </div>
  );
}

export default ScreenSizeModal;

const styles = { text: { margin: "1em 0", fontSize: 16 } };
