import { motion } from "framer-motion";
import React from "react";

function Tutorial({ currentPage, showTutorial = true }) {
  // const [interactionCount, setInteractionCount] = useState(0);

  // useEffect(() => {
  //   currentPage > 0 && setInteractionCount(interactionCount + 1);
  // }, [currentPage]);

  const variants = {
    before: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    after: { opacity: 0, y: 10 },
  };

  if (!showTutorial) return null;
  return (
    <>
      <motion.div
        variants={variants}
        initial="before"
        animate={
          currentPage === 0 ? "visible" : currentPage >= 1 ? "after" : "before"
        }
        transition={{ duration: 0.5, ease: "linear" }}
        style={styles.wrapper}
      >
        <p
          style={{
            color: "white",
            fontSize: window.innerWidth > 600 ? "1em" : 10,
          }}
        >
          Click anywhere on the Momentum Solar Lookboook to begin
        </p>
      </motion.div>

      <motion.div
        variants={variants}
        initial="before"
        animate={
          currentPage < 1 ? "before" : currentPage >= 2 ? "after" : "visible"
        }
        transition={{ duration: 0.5, ease: "linear", delay: 0.5 }}
        style={styles.wrapper}
      >
        <p
          style={{
            color: "white",
            fontSize: window.innerWidth > 600 ? "1em" : 10,
          }}
        >
          Click the right and left pages to continue turning through
        </p>
      </motion.div>
    </>
  );
}

export default Tutorial;

const styles = {
  wrapper: {
    position: "fixed",
    background: "rgba(0,0,0, 0.4)",
    backdropFilter: "blur(4px)",
    zIndex: 3000,
    top: 0,
    borderRadius: 6,
    padding: "0.5em 1em",
    marginTop: "0.5em",
    marginLeft: "17em",
    marginRight: "16px",
  },
};
