import React from "react";

import { ReactComponent as PanelIcon } from "../../assets/PanelIcon.svg";
import { ReactComponent as InverterIcon } from "../../assets/InverterIcon.svg";
import { ReactComponent as LightbulbIcon } from "../../assets/LightbulbIcon.svg";

function Stats({ installData }) {
  const { panels, inverters, production } = installData;

  return (
    <div style={styles.wrapper}>
      <div style={styles.item}>
        <div style={styles.svgWrapper}>
          <PanelIcon style={styles.svg} />
        </div>
        <div style={styles.textWrapper}>
          <h2 style={styles.mainText}>{panels}</h2>
          <h4 style={styles.subText}>Solar Panels</h4>
        </div>
      </div>
      <div
        style={{
          ...styles.item,
          margin: window.innerHeight > 900 ? "0.8rem 0" : "8px 0",
        }}
      >
        <div style={styles.svgWrapper}>
          <InverterIcon style={styles.svg} />
        </div>
        <div style={styles.textWrapper}>
          <h2 style={styles.mainText}>{inverters}</h2>
          <h4 style={styles.subText}>Microinverters</h4>
        </div>
      </div>
      <div style={styles.item}>
        <div style={styles.svgWrapper}>
          <LightbulbIcon style={styles.svg} />
        </div>
        <div style={styles.textWrapper}>
          <h2 style={styles.mainText}>{production} kW</h2>
          <h4 style={styles.subText}>Estimated Production</h4>
        </div>
      </div>
    </div>
  );
}

export default Stats;

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItem: "space-between",
    flexDirection: "column",
  },
  item: { display: "flex", alignItems: "center", flex: 1 },
  mainText: {
    fontFamily: "Gotham",
    whiteSpace: "nowrap",
    fontSize: window.innerWidth > 900 ? "1rem" : 8,
  },
  subText: {
    color: "#33ccff",
    fontFamily: "Gotham",
    fontSize: window.innerWidth > 900 ? "0.8rem" : 8,
  },
  svgWrapper: {
    maxHeight: "2.5em",
    aspectRatio: 1,
    marginRight: "1em",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
  },
  svg: {
    height: window.innerWidth > 900 ? "80%" : 18,
    width: window.innerWidth > 900 ? "80%" : 18,
  },
};
