import React from "react";

import Signature from "../../assets/ArthurSignature.png";

import { ReactComponent as MSLogoText } from "../../assets/MSLogoText.svg";
import { ReactComponent as MSLogo } from "../../assets/MSLogo.svg";

function About1() {
  return (
    <div className="pageWrapper" style={styles.background}>
      <MSLogo width="60%" height="60%" style={styles.logo} />
      <div className="pageContent" style={styles.content}>
        <MSLogoText
          width="90%"
          style={{ paddingBottom: window.innerWidth > 800 ? "1em" : 0 }}
        />
        <p style={styles.text}>
          As a co-founder and CEO of Momentum Solar, I believe strongly in two
          core operating principles: deliver best-in-class products and
          services, and always exceed our customers' expectations.
        </p>
        <p style={styles.text}>
          You have my personal commitment to an unparalleled solar experience
          from myself and our employees. Join us in taking an important step
          forward that is not only good for the environment but also great for
          your financial security as well. If you decide to make the move from
          carbon emitting electricity sources and rising energy costs to lower
          cost electricity generated from the sun, we’d love the opportunity to
          guide you through the process.
        </p>
        <img
          src={Signature}
          alt="Signed Arthur Souritzidis"
          style={styles.signature}
        />
        <p>Arthur Souritzidis, Co-Founder & CEO</p>
      </div>
    </div>
  );
}

export default About1;

const styles = {
  background: {
    background: "linear-gradient(#D1F4FF , #fff 50%)",
    alignItems: "flex-end",
    zIndex: -10,
  },
  content: {
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  logo: {
    position: "absolute",
    top: "-30%",
    left: "-30%",
    zIndex: -1,
  },
  signature: {
    height: "10%",
    maxHeight: "4rem",
    objectFit: "contain",
    marginTop: "1em",
    float: "left",
    maxWidth: "200px",
  },
};
