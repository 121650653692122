import CT1 from "../../assets/statePhotos/CT Install 1.png";
import CT2 from "../../assets/statePhotos/CT Install 2.png";
import CT3 from "../../assets/statePhotos/CT Install 3.png";
import CT4 from "../../assets/statePhotos/CT Install 4.png";

const CT = {
  coordinates: {
    center: { lat: 41.76167945279012, lng: -72.66118052853263 },
    zoom: 10,
  },
  fullName: "Connecticut",
  installs: [
    {
      photo: CT1,
      panels: "18 Q Cell 380W",
      inverters: "18 Enphase IQ-7+",
      production: "12049",
      customer: {
        quote:
          "The crew that came over to install the panels were very polite and knew exactly what to do. I’m very satisfied with their work. Job well done.",
        name: "James S.",
        location: "Hatford, CT",
        date: "September 2021",
      },
      layout: 1,
    },
    {
      photo: CT2,
      panels: "16 Q Cell 340W",
      inverters: "16 Enphase IQ-7",
      production: "5249",
      customer: {
        quote:
          "Momentum’s staff did an excellent job guiding us and  answering all our questions along the way. Overall, it  was a stress-free experience. Their customer service is  good all-round. Everyone was willing to give me the  information I needed about solar, from the office staff  to the installers.",
        name: "Belinda W.",
        location: "Stamford, CT",
        date: "December 2021",
      },
      layout: 2,
    },
    {
      photo: CT3,
      panels: "14 Q Cell 340W",
      inverters: "14 Enphase IQ-7",
      production: "6493",
      customer: {
        quote:
          "It was a good decision to hire Momentum Solar to get a solar energy system at my new home. Their installers were very professional and polite. They treated my home with respect and ensured that nothing would be damaged as they got the work done.",
        name: "Richard S.",
        location: "Waterbury, CT",
        date: "July 2021",
      },
      layout: 3,
    },
    {
      photo: CT4,
      panels: "36 Q Cell 340W",
      inverters: "36 Enphase IQ-7",
      production: "10989",
      customer: {
        quote:
          "The team at Momentum Solar is experts at their jobs. They are easy to approach, so we did not have any issues during the whole installation process. I highly recommend them for solar installation services.",
        name: "Ernest M.",
        location: "East Berlin, CT",
        date: "October 2021",
      },
      layout: 1,
    },
  ],
  energy: [
    {
      quote: "Connecticut consumers will feel these prices directly.",
      source: "ctmirror.org",
      href: "https://ctmirror.org/",
    },
    {
      quote:
        "Eversource said the rising cost of natural gas will also increase the cost of electricity for its customers.",
      source: "nbcconnecticut.com",
      href: "https://www.nbcconnecticut.com/",
    },
    {
      quote: "Electric bills will be increasing by 21% on Jan. 1.",
      source: "fox61.com",
      href: "https://www.fox61.com/",
    },
  ],
};

export default CT;
