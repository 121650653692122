import AZ1 from "../../assets/statePhotos/AZ Install 1.png";
import AZ2 from "../../assets/statePhotos/AZ Install 2.png";
import AZ3 from "../../assets/statePhotos/AZ Install 3.png";
import AZ4 from "../../assets/statePhotos/AZ Install 4.png";

const AZ = {
  fullName: "Arizona",
  installs: [
    {
      photo: AZ1,
      panels: "8 Q Cell 340W",
      inverters: "8 Enphase IQ-7",
      production: "4253",
      customer: {
        quote:
          "I was a skeptical person when it came to solar panels. I have been researching and looking around at different solar panel companies online, and I have also talked to several people who have bought solar panels. Momentum Solar is the best company I’ve found in the market because of their great customer service and economical prices. The crew was very professional and offered a smooth transaction from start to finish.",
        name: "Tim A.",
        location: "Phoenix, AZ",
        date: "March 2021",
      },
      layout: 1,
    },
    {
      photo: AZ2,
      panels: "9 Q Cell 340W",
      inverters: "9 Enphase IQ-7",
      production: "4566",
      customer: {
        quote:
          "I got a response back within 15 minutes when I inquired about switching to solar. Because they were the most courteous and offered the best prices, I chose to work with them. Although I am a busy person, they were really easy-going and quick - The crew did everything promptly! Overall, excellent service, and I’m glad that I made the shift.",
        name: "Sophia M.",
        location: "Scottsdale, AZ",
        date: "August 2021",
      },
      layout: 2,
    },
    {
      photo: AZ3,
      panels: "9 Q Cell 340W",
      inverters: "9 Enphase IQ-7",
      production: "5037",
      customer: {
        quote:
          "Momentum Solar set the perfect example of being the best in the solar industry. We had a seamless and transparent experience from the initial quote to the final installation. The staff was responsive to our questions and guided us on what we needed to know. The crew made us feel like family instead of just another customer. We can’t wait to go solar again with our next house and recommend Momentum Solar for any solar needs!",
        name: "Dolores D.",
        location: "Tempa, AZ",
        date: "September 2021",
      },
      layout: 1,
    },
    {
      photo: AZ4,
      panels: "32 Q Cell 340W",
      inverters: "32 Enphase IQ-7",
      production: "16724",
      customer: {
        quote:
          "I found Momentum Solar the best company that provides a one-time installation process that significantly lowered energy costs throughout the lifetime. The company’s employees are highly professional and very helpful. They genuinely care about the concerns of their customers, and they want to make sure their customers are happy and receiving excellent service.",
        name: "Emma D.",
        location: "Glendale, AZ",
        date: "July 2021",
      },
      layout: 1,
    },
  ],
  energy: [
    {
      quote:
        "APS attributes the higher costs to the hottest summer on record in Arizona, which forced the company to buy more gas for its power plants as well as power from other utilities.",
      source: "azcentral.com",
      href: "https://www.azcentral.com/",
    },
    {
      quote: "Some of the toughest increases are happening in energy costs.",
      source: "azcapitoltimes.com",
      href: "https://azcapitoltimes.com/",
    },
    {
      quote:
        "TEP’s average residential rate rose an average 2.7% annually, from about 9.6 cents per kilowatt hour to 12.2 cents in 2018.",
      source: "tucson.com",
      href: "https://tucson.com/",
    },
  ],
  coordinates: {
    center: { lat: 33.44695931726903, lng: -112.07438193169205 },
    zoom: 8,
  },
};

export default AZ;
