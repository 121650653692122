import React, { useEffect, useState, useRef } from "react";

import GoogleMapReact from "google-map-react";
import { ReactComponent as CollapseIcon } from "../../assets/CollapseIcon.svg";
import Pin from "../../assets/MapPin.svg";
import GreyMarker from "../../assets/GreyPin.png";

import mapStyle from "../../Utils/mapStyle";

const mapAreaOptions = {
  strokeColor: "#336699",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#336699",
  fillOpacity: 0.2,
};

const drawPin = ({ map, maps }, pinData, pin) => {
  const position = {
    lat: pinData.latitude ?? pinData.lat,
    lng: pinData.longitude ?? pinData.lng,
  };
  const newMarker = new maps.Marker({
    position,
    icon: pin ?? GreyMarker,
    // animation: maps.Animation.DROP,
    map,
    style: { color: "red" },
  });
  return newMarker;
};

function FullscreenMap({
  fullscreenMapIsOpen,
  setFullscreenMapIsOpen,
  stateData,
  userLocation,
  pinsRef,
  stateAbbr,
  setOnPinsUpdated,
}) {
  const [userLocationDrawn, setUserLocationDrawn] = useState(false);
  const [pinUpdates, setPinUpdates] = useState(0);
  const state = stateAbbr;
  const mapRef = useRef();
  const markersRef = useRef({});
  const pins = pinsRef.current;

  const updateMarkersOnMap = () => {
    if (!!mapRef.current) {
      const nOfMarkers = Object.keys(markersRef.current).length;
      const newPins = pins.slice(pins.length - pins.length - nOfMarkers);
      let newMarkers = [];
      newPins.forEach((pinData) => {
        const key = `${pinData.latitude}_${pinData.longitude}`;
        if (
          !markersRef.current[key] &&
          pinData.latitude &&
          pinData.longitude &&
          state.toLowerCase() === pinData.state.toLowerCase()
        ) {
          markersRef.current[key] = drawPin(mapRef.current, pinData);
          newMarkers.push(markersRef.current[key]);
        }
      });
    }
  };

  useEffect(() => {
    updateMarkersOnMap();
    setTimeout(updateMarkersOnMap, 1000);
  }, []);

  // here we assign the pun update counter to increment each time
  // the pins are updated on the top level of the app. The useEffect
  // below this one watches those updates.
  useEffect(() => {
    const updateFunction = () => {
      setPinUpdates((prev) => prev + 1);
    };
    setOnPinsUpdated(updateFunction);
  }, []);

  // Any time the pins are updated or we change the page to this one
  // we update the pins on the map.
  // NOTES: The best practice would be to have the updateMakersOnMap function
  // directly called inside of the onPinsUpdated fn but I was unable to
  // have those updates appear on the map correctly. If you figure it out,
  // good on you.
  useEffect(() => {
    if (mapRef.current) updateMarkersOnMap();
  }, [pinUpdates]);

  useEffect(() => {
    if (mapRef.current) {
      !userLocationDrawn &&
        userLocation &&
        drawPin(mapRef.current, userLocation, Pin);
      setUserLocationDrawn(true);
    }
  }, [userLocation, mapRef.current]);

  return (
    <div style={styles.mapWrapper}>
      <CollapseButton setFullscreenMapIsOpen={setFullscreenMapIsOpen} />
      <div
        className="card"
        style={{
          background: "white",
          position: "absolute",
          zIndex: 10000,
          marginLeft: "2em",
        }}
      >
        <div>
          <h2>Momentum Solar in Your </h2>
          <h1>hometown</h1>
        </div>
      </div>

      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCR7yTvbSisKRJcpb20uNWQ_wZLWuCive8" }}
        defaultCenter={stateData.coordinates.center}
        defaultZoom={10}
        zoom={stateData.coordinates.zoom}
        center={stateData.coordinates.center}
        options={{
          fullscreenControl: false,
          draggable: true,
          zoomControl: true,
          scrollwheel: true,
          disableDoubleClickZoom: true,
          styles: mapStyle,
          maxZoom: 14,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(google) => {
          if (userLocation && !userLocationDrawn) {
            setUserLocationDrawn(true);
            drawPin(google, userLocation, Pin);
          }
          mapRef.current = google;
        }}
      />
    </div>
  );
}

const CollapseButton = ({ setFullscreenMapIsOpen }) => {
  return (
    <div
      style={styles.collapseButton}
      onClick={(e) => {
        e.stopPropagation();
        setFullscreenMapIsOpen(false);
      }}
    >
      <CollapseIcon />
    </div>
  );
};

export default FullscreenMap;

const styles = {
  mapWrapper: {
    position: "absolute",
    height: "100vh",
    width: "100vw",
    background: "white",
    zIndex: 10000,
  },
  collapseButton: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 11000,
    background: "white",
    margin: "1em",
    padding: "0.5em",
    color: "#ADADAD",
    cursor: "pointer",
  },
};
