import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";

import { ReactComponent as ChevronLeft } from "../../assets/ChevronLeft.svg";
import { ReactComponent as MapIcon } from "../../assets/MapIcon.svg";
import GreyMarker from "../../assets/GreyPin.png";
import Pin from "../../assets/MapPin.svg";

import mapStyle from "../../Utils/mapStyle";

const useSidebarPageTurner = false;

const mapAreaOptions = {
  strokeColor: "#336699",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#336699",
  fillOpacity: 0.2,
};

const GoogleMap = ({
  handlePageTurn,
  setFullscreenMapIsOpen,
  stateData,
  userLocation,
  pinsRef,
  stateAbbr,
  currentPage,
  setOnPinsUpdated,
}) => {
  const [pinUpdates, setPinUpdates] = useState(0);
  const state = stateAbbr;
  const mapRef = useRef();
  const markersRef = useRef({});
  const pins = pinsRef.current;
  const [userLocationDrawn, setUserLocationDrawn] = useState(false);

  const updateMarkersOnMap = () => {
    if (!!mapRef.current && currentPage === 12) {
      const nOfMarkers = Object.keys(markersRef.current).length;
      const newPins = pins.slice(pins.length - pins.length - nOfMarkers);
      let newMarkers = [];
      newPins.forEach((pinData) => {
        const key = `${pinData.latitude}_${pinData.longitude}`;
        if (
          !markersRef.current[key] &&
          pinData.latitude &&
          pinData.longitude &&
          state.toLowerCase() === pinData.state.toLowerCase()
        ) {
          markersRef.current[key] = drawPin(mapRef.current, pinData);
          newMarkers.push(markersRef.current[key]);
        }
      });
    }
  };

  // here we assign the pun update counter to increment each time
  // the pins are updated on the top level of the app. The useEffect
  // below this one watches those updates.
  useEffect(() => {
    const updateFunction = () => {
      currentPage === 12 && setPinUpdates((prev) => prev + 1);
    };
    setOnPinsUpdated(updateFunction);
  }, []);

  // Any time the pins are updated or we change the page to this one
  // we update the pins on the map.
  // NOTES: The best practice would be to have the updateMakersOnMap function
  // directly called inside of the onPinsUpdated fn but I was unable to
  // have those updates appear on the map correctly. If you figure it out,
  // good on you.
  useEffect(() => {
    updateMarkersOnMap();
  }, [pinUpdates, currentPage]);

  useEffect(() => {
    if (userLocation && !userLocationDrawn && mapRef.current) {
      drawPin(mapRef.current, userLocation, Pin);
      setUserLocationDrawn(true);
    }
  }, [userLocation, mapRef.current]);

  const drawPin = ({ map, maps }, pinData, pin) => {
    const position = {
      lat: pinData.latitude ?? pinData.lat,
      lng: pinData.longitude ?? pinData.lng,
    };
    const newMarker = new maps.Marker({
      position,
      icon: pin ?? GreyMarker,
      map,
      style: { color: "red" },
    });
    return newMarker;
  };

  return (
    <div
      className="pageWrapper"
      onClick={(e) => useSidebarPageTurner && e.stopPropagation()}
    >
      {useSidebarPageTurner && <PageTurner handlePageTurn={handlePageTurn} />}
      {
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCR7yTvbSisKRJcpb20uNWQ_wZLWuCive8" }}
          defaultCenter={stateData.coordinates.center}
          defaultZoom={10}
          zoom={stateData.coordinates.zoom}
          center={stateData.coordinates.center}
          options={{
            fullscreenControl: useSidebarPageTurner,
            draggable: useSidebarPageTurner,
            zoomControl: useSidebarPageTurner,
            scrollwheel: useSidebarPageTurner,
            disableDoubleClickZoom: useSidebarPageTurner,
            styles: mapStyle,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={(google) => {
            if (userLocation && !userLocationDrawn) {
              setUserLocationDrawn(true);
              drawPin(google, userLocation, Pin);
            }
            mapRef.current = google;
          }}
        />
      }
      <div className="pageContent" style={styles.content}>
        <div className="card" style={{ width: "50%", background: "white" }}>
          <div>
            <h2>Momentum Solar in Your </h2>
            <h1>hometown</h1>
          </div>
          <p>
            Use our solar install map to see where we have helped others unlock
            their savings.
          </p>
          {!useSidebarPageTurner && (
            <ExploreButton setFullscreenMapIsOpen={setFullscreenMapIsOpen} />
          )}
        </div>
      </div>
    </div>
  );
};

const PageTurner = ({ handlePageTurn }) => {
  return (
    <div style={styles.pageTurnerBackground} onClick={() => handlePageTurn(-1)}>
      <ChevronLeft style={{ stroke: "#fff" }} />
    </div>
  );
};

// const FullscreenButton = ({ setFullscreenMapIsOpen }) => {
//   return (
//     <div
//       style={styles.fullscreenButton}
//       onClick={(e) => {
//         e.stopPropagation();
//         setFullscreenMapIsOpen(true);
//       }}
//     >
//       <ExpandIcon />
//     </div>
//   );
// };

const ExploreButton = ({ setFullscreenMapIsOpen }) => {
  return (
    <button
      style={styles.exploreButton}
      onClick={(e) => {
        e.stopPropagation();
        setFullscreenMapIsOpen(true);
      }}
    >
      <MapIcon
        height={window.innerWidth > 900 ? 32 : 20}
        style={{ color: "white" }}
      />
      <p style={styles.exploreButtonText}>Explore Your Area</p>
    </button>
  );
};

export default GoogleMap;
const styles = {
  content: {
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    marginTop: "0px",
    position: "absolute",
  },
  pageTurnerBackground: {
    width:
      window.innerWidth > 1200
        ? "clamp(2em, 8%, 74px)"
        : "clamp(1em, 5%, 74px)",
    height: "100%",
    background: "#0009",
    position: "absolute",
    zIndex: 10000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  fullscreenButton: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 10000,
    background: "white",
    margin: "1em",
    padding: "0.5em",
    color: "#ADADAD",
    cursor: "pointer",
  },
  exploreButton: {
    marginTop: "0.5em",
    background: "linear-gradient(90deg ,#00ccff, #336699)",
    border: "none",
    width: "100%",

    padding: "2px 1em",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  exploreButtonText: {
    color: "white",
    fontFamily: "Gotham",
    marginLeft: "0.5em",
  },
};
