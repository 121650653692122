import React from "react";

function PageNumber({ number = 0, isBack = false }) {
  const displayNumber = number < 10 ? "0" + number : number;
  return (
    <div
      className="page-number"
      style={{
        textAlign: isBack ? "end" : "start",
      }}
    >
      <p
        className="page-number-text"
        style={{
          transform: `rotateY(${isBack ? 0 : 180}deg)`,
          backfaceVisibility: isBack ? "hidden" : "visible",
        }}
      >
        {displayNumber}
      </p>
    </div>
  );
}

export default PageNumber;
