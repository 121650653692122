import React from "react";

import { ReactComponent as DirtyEnergyNoSmoke } from "../../assets/DirtyEnergyNoSmoke.svg";

import { motion } from "framer-motion";

import AnimatedSmoke from "../../Components/AnimatedSmoke";

function CleanVsDirty1({ quotes, currentPage }) {
  const renderQuotes = () => {
    return quotes.map(({ quote, source, href }, i) => {
      return (
        <div style={styles.quoteWrapper} key={i}>
          <p style={styles.text}>"{quote}"</p>
          <div
            style={{
              ...styles.line,
              marginBottom: window.innerWidth > 800 ? 4 : 0,
            }}
          />
          <a
            onClick={(e) => e.stopPropagation()}
            style={styles.source}
            target="_blank"
            href={href}
            rel="noreferrer"
          >
            {source}
          </a>
        </div>
      );
    });
  };

  return (
    <div className="pageWrapper" style={styles.background}>
      <div className="pageContent" style={styles.content}>
        <div style={{ zIndex: 10, display: "flex", flexDirection: "column" }}>
          <div style={{ alignSelf: "flex-start" }}>
            <h2>Dirty</h2>
            <h1>energy</h1>
          </div>

          <p style={styles.subTitle}>Utility rates are on the rise</p>

          <div style={styles.quotesWrapper}>{renderQuotes()}</div>
        </div>
        {currentPage === 4 && (
          <motion.div>
            <AnimatedSmoke
              height="30%"
              width="20%"
              color="#EBECF3"
              style={{
                position: "absolute",
                top: "42%",
                left: "26.5%",
              }}
            />
            <AnimatedSmoke
              height="30%"
              width="20%"
              color="#EBECF3"
              style={{
                position: "absolute",
                top: "47%",
                left: "39%",
              }}
            />
            <AnimatedSmoke
              height="20%"
              width="10%"
              color="#B9C0CA"
              duration={10}
              style={{
                position: "absolute",
                top: "49%",
                left: "54.5%",
              }}
            />
            <AnimatedSmoke
              height="20%"
              width="10%"
              color="#B9C0CA"
              duration={10}
              style={{
                position: "absolute",
                top: "53%",
                left: "59%",
              }}
            />
            <AnimatedSmoke
              height="20%"
              width="10%"
              color="#B9C0CA"
              duration={10}
              style={{
                position: "absolute",
                top: "54%",
                left: "63.5%",
              }}
            />
          </motion.div>
        )}
        <DirtyEnergyNoSmoke width="100%" height={"100%"} style={styles.svg} />
      </div>
    </div>
  );
}

export default CleanVsDirty1;

const styles = {
  background: {
    background: "linear-gradient(#fff, #b1b1b1)",
  },
  content: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  quotesWrapper: {
    marginTop: window.innerWidth > 800 ? "3em" : "1em",
    display: "flex",
    justifyContent: "space-between",
  },
  quoteWrapper: { width: "30%" },
  text: {
    color: "#707070",
    fontFamily: "LatoBold",
    fontSize: window.innerWidth > 800 ? "0.9rem" : 10,
  },
  source: {
    color: "#707070",
    fontStyle: "italic",
    fontSize: window.innerWidth > 800 ? "0.9rem" : 10,
  },
  line: {
    height: window.innerWidth > 800 ? "4px" : "2px",
    width: "75%",
    background: "-webkit-linear-gradient(45deg, #33ccff, #336699)",
    margin: window.innerWidth > 800 ? "8px 0" : "4px 0",
  },
  subTitle: {
    fontSize: window.innerWidth > 800 ? "0.9rem" : 12,
    color: "#707070",
    marginTop: "1em",
  },
  svg: {
    position: "absolute",
    width: "100%",
    height: "auto",
    bottom: "0",
    zIndex: 1,
  },
};
