import React from "react";

import { motion } from "framer-motion";

function AnimatedSmoke({
  duration = 15,
  color,
  width = "100%",
  height = "100%",
  style = {},
}) {
  const transition = {
    duration,
    ease: "easeOut",
    repeat: Infinity,
  };

  const spawnBigParticles = (n) => {
    const particles = [];
    const randomEndpointRange = 50;
    const randomSizeRange = 3;
    const baseStartingSize = 5;
    let previousEndX;
    for (let i = 0; i < n; i++) {
      const delay = ((i / n) * 0.9 + Math.random() * 0.1) * duration;
      const startingSize = Math.random() * randomSizeRange + baseStartingSize;
      const endSize = Math.random() * 5 + 15;
      const randomEndX =
        (randomEndpointRange * Math.round((Math.random() - 0.5) * 3)) / 3;
      const endX =
        (randomEndX === previousEndX ? previousEndX * -1 : randomEndX) + 50;
      const endY = endSize * 0.7;

      particles.push(
        <motion.circle
          id="Ellipse_3"
          data-name="Ellipse 3"
          r={startingSize}
          transform="translate(0 3.268)"
          stroke={color}
          fill={color}
          strokeMiterlimit="10"
          strokeWidth="2"
          initial={{
            opacity: 1,
            cy: 100 + 30,
            cx: 50,
            r: startingSize,
          }}
          animate={{ opacity: [1, 1, 0], cy: endY, cx: endX, r: endSize }}
          transition={{
            ...transition,
            delay,
          }}
          key={i}
        />
      );
    }
    return particles;
  };

  const spawnSmallParticles = (n) => {
    const particles = [];
    const randomEndpointRange = 40;
    const randomSizeRange = 2;
    const baseStartingSize = 1;
    let previousEndX;
    for (let i = 0; i < n; i++) {
      const delay = (i / n) * duration;
      const startingSize = Math.random() * randomSizeRange + baseStartingSize;
      const endSize = Math.random() + startingSize;
      const randomEndX =
        (randomEndpointRange * Math.round((Math.random() - 0.5) * 3)) / 3;
      const endX =
        (randomEndX === previousEndX ? previousEndX * -1 : randomEndX) +
        (Math.random() < 0.5 ? 10 : 90);
      const endY = 40;

      particles.push(
        <motion.circle
          id="Ellipse_3"
          data-name="Ellipse 3"
          r={startingSize}
          transform="translate(0 3.268)"
          stroke={color}
          fill={color}
          strokeMiterlimit="10"
          strokeWidth="2"
          initial={{
            opacity: 1,
            cy: 100 + 30,
            cx: 50,
            r: startingSize,
          }}
          animate={{ opacity: [1, 1, 0], cy: endY, cx: endX, r: endSize }}
          transition={{ ...transition, delay }}
          key={i}
        />
      );
    }
    return particles;
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 100 100"
      width={width}
      height={height}
      style={style}
    >
      {spawnBigParticles(8)}
      {spawnSmallParticles(8)}
    </svg>
  );
}

export default AnimatedSmoke;
