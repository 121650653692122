import React from "react";

import AboutBG from "../../assets/AboutBG.png";

function About2() {
  return (
    <div className="pageWrapper" style={styles.background}>
      <div className="pageContent" style={styles.content}>
        <div style={styles.item}>
          <h4>solar power</h4>
          <h3>redefined</h3>
          <p style={styles.text}>
            We enable our customers to take control over their rising energy
            costs and start generating cleaner, price-protected power.
          </p>
        </div>
        <div style={styles.item}>
          <h4>customer experience</h4>
          <h3>redefined</h3>
          <p style={styles.text}>
            As an owner-operated company, our customers are our #1 priority. We
            are with you every step of the way to make going solar easy for you.
          </p>
        </div>
        <div style={styles.item}>
          <h4>community</h4>
          <h3>redefined</h3>
          <p style={{ ...styles.text, ...styles.shadow, width: "50%" }}>
            We're building a solar community comprised of our customers, our
            employees and the neighborhoods where we offer affordable
            {window.innerWidth < 900 ? <br /> : ""} home solar.
          </p>
        </div>
        <img src={AboutBG} alt={"House background"} style={styles.img}></img>
      </div>
    </div>
  );
}

export default About2;

const styles = {
  background: {
    background: "linear-gradient(#D1F4FF , #fff 50%)",
    overflow: "hidden",
  },
  content: {
    flexDirection: "column",
    position: "relative",
    height: "100%",
    zIndex: 10,
  },
  item: { marginBottom: "10px" },
  text: { marginTop: "8px" },
  img: {
    position: "absolute",
    right:
      window.innerWidth > 900
        ? "calc(clamp(-2em, -8%, -74px) * 2 - 10px)"
        : -72,
    bottom: 0,
    width: window.innerWidth > 900 ? "100%" : "80%",
    zIndex: -1,
  },
  shadow: {
    textShadow:
      "3px 3px 5px  #fffb, -3px -3px 5px  #fffb, 3px -3px 5px  #fffb, -3px 3px 5px  #fffb",
  },
};
