import React from "react";

import Background from "../../assets/WeMakeSolarBeautifulBG.png";

function WeMakeSolarBeautiful() {
  return (
    <div className="pageWrapper">
      <div className="pageContent" style={styles.content}>
        <div className="card" style={{ background: "white" }}>
          <div>
            <h2>We make home</h2>
            <h1>solar beautiful</h1>
          </div>
          <p style={styles.text}>
            Our customized solar designs are tailored to your needs and crafted
            for maximum production. Home solar has never been more stunning.
          </p>
        </div>
      </div>
      <img src={Background} alt="Solar Offset Background" style={styles.img} />
    </div>
  );
}

export default WeMakeSolarBeautiful;

const styles = {
  content: {
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "50%",
    height: "100%",
  },
  text: {
    marginTop: "1em",
    marginBottom: window.innerWidth > 800 ? "5em" : "1em",
  },
  img: {
    position: "absolute",
    height: "100%",
    zIndex: 0,
    right: "-56%",
  },
};
