import React from "react";

import Background from "../../assets/EnphaseHomeBG.png";

function SystemUpgrades1() {
  return (
    <div className="pageWrapper">
      <img
        src={Background}
        alt="Enphase Cabinets"
        style={{
          position: "absolute",
          height: "100%",
          zIndex: 0,
          right: "-21%",
        }}
      />
    </div>
  );
}

export default SystemUpgrades1;
