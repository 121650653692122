import React from "react";

import { ReactComponent as XIcon } from "../../assets/XIcon.svg";

function QuoteModal({ setShowingModal, customer }) {
  console.table(customer);
  const { name, quote, location, date } = customer;
  return (
    <div style={styles.wrapper}>
      <button onClick={() => setShowingModal()} style={styles.closeButton}>
        <XIcon />
      </button>
      <h3 style={styles.name}>{name}</h3>
      <p>{quote}</p>
      <div style={styles.horizontalLine} />
      <div style={styles.footer}>
        <h4 style={styles.location}>{location}</h4>
        {/* <div style={styles.verticalLine} /> */}
        {/* <h4 style={styles.date}>{date}</h4> */}
      </div>
    </div>
  );
}

export default QuoteModal;

const styles = {
  closeButton: {
    position: "absolute",
    top: -32,
    right: -32,
    border: "none",
    background: "transparent",

    padding: 8,
  },
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    flexDirection: "column",
    position: "relative",
  },
  name: {
    color: "#33ccff",
    fontSize: window.innerWidth > 900 ? "1.2rem" : 11,
    marginBottom: window.innerWidth > 900 ? "4px" : "0px",
  },
  horizontalLine: {
    height: window.innerWidth > 900 ? "clamp(5px, 0.3rem,10px)" : 2,
    width: "90%",
    background: "-webkit-linear-gradient(45deg, #33ccff, #336699)",
    margin: "4px 0",
  },
  footer: {
    display: "flex",
  },
  location: {
    color: "#AFB0B3",
    fontFamily: "Gotham",
    // borderRight: "2px solid #336699",
    marginRight: "0.5em",
    paddingRight: "0.5em",
    fontSize: window.innerWidth > 900 ? "0.8rem" : 8,
  },
  date: {
    color: "#33ccff",
    fontFamily: "Gotham",
    fontSize: window.innerWidth > 900 ? "0.8rem" : 8,
  },

  readMore: {
    color: "#369",
  },
};
