import React from "react";

import { ReactComponent as MomentumSolar } from "../../assets/businessLogos/MomentumSolar.svg";
import { ReactComponent as MomentumHome } from "../../assets/businessLogos/MomentumHome.svg";
import { ReactComponent as MomentumPowerSolutions } from "../../assets/businessLogos/MomentumPowerSolutions.svg";
import { ReactComponent as MomentumRoofing } from "../../assets/businessLogos/MomentumRoofing.svg";

function WeAreMomentum2() {
  return (
    <div className="pageWrapper">
      <div className="pageContent" style={styles.content}>
        <div style={styles.titleWrapper}>
          <h2>Welcome to our</h2>
          <h1>family</h1>
        </div>

        <div style={styles.itemsWrapper}>
          <div style={styles.left}>
            <div style={styles.item}>
              <MomentumSolar style={styles.logo} width={"50%"} height="100%" />
              <p>
                Momentum Solar is our flagship company. Dedicated to
                professionally improving your home with state of the art solar
                panels and energy storage equipment.
              </p>
              <div style={styles.line} />
            </div>

            <div style={styles.item}>
              <MomentumPowerSolutions
                style={styles.logo}
                width={window.innerWidth > 1350 ? "50%" : "40%"}
                height="100%"
              />
              <p>
                Momentum Home is a part of the Momentum family. Dedicated to
                professionally improving your home with state of the art siding,
                roofing and windows.
              </p>
              <div style={styles.line} />
            </div>
          </div>

          <div style={styles.right}>
            <div style={{ ...styles.item, alignItems: "flex-end" }}>
              <MomentumHome
                style={styles.logo}
                width={window.innerWidth > 1350 ? "40%" : "30%"}
                height="100%"
              />
              <p>
                Momentum Power Solutions works in tandem with our Home and solar
                branch. Dedicated to professionally improving your home with the
                latest smart technology.
              </p>
              <div
                style={{
                  ...styles.line,
                  justifySelf: "flex-end",
                }}
              />
            </div>
            <div style={{ ...styles.item, alignItems: "flex-end" }}>
              <MomentumRoofing
                style={styles.logo}
                width={window.innerWidth > 1350 ? "40%" : "30%"}
                height="100%"
              />
              <p>
                Momentum Roofing and Solar is a part of the Momentum family.
                Dedicated to professionally improving your home with state of
                the art siding, roofing and windows.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeAreMomentum2;

const styles = {
  titleWrapper: {
    alignSelf: "flex-end",
    textAlign: "end",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  item: {
    width: "120%",
    display: "flex",
    flexDirection: "column",
  },
  line: {
    height: 1,
    width: "65%",
    background: "#A5A5A5",
    marginTop: "1em",
    marginBottom: "1em",
    opacity: 0.2,
  },
  itemsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: "auto",
    height: "100%",
  },
  right: {
    width: "50%",
    textAlign: "end",
    transform: "translateX(-20%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  left: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginBottom: "10%",
    marginTop: "-10%",
  },
  logo: {
    marginBottom: "1em",
  },
};
