import statesData from "./states/index";

export const cleanStateParam = (stateParam) => {
  const cleaned = stateParam.replaceAll(/\s|\/|%20/g, "").toUpperCase();
  if (cleaned.length === 2) return cleaned;
  return Object.keys(statesData).find(
    (key) =>
      statesData[key].fullName.toUpperCase().replace(/\s|%20/g, "") === cleaned
  );
};
