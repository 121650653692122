import React from "react";

function LabelCard({ style }) {
  return (
    <div className="card" style={style}>
      <h2>Customer</h2>
      <h1>Testimonials</h1>
    </div>
  );
}

export default LabelCard;
