import React from "react";
import CollapsibleMenu from "./CollapsibleMenu";

const pages = [
  { label: "Home", number: 0 },
  { label: "About", number: 1 },
  { label: "Gaining Momentum", number: 2 },
  { label: "We Are Momentum", number: 3 },
  { label: "Clean vs. Dirty Energy", number: 4 },
  { label: "Benefits of Solar", number: 5 },
  { label: "Customized System Design", number: 6 },
  { label: "Industry Finest Equipment", number: 7 },
  { label: "System Upgrades", number: 8 },
  { label: "Referral Program", number: 9 },
  { label: "White-glove Service", number: 9 },
  { label: "Customer Testimonials", number: 10 },
  { label: "Customer Testimonials", number: 10 },
  { label: "Thank You", number: 12 },
];

function Menu({
  currentPage = 0,
  handleGoToPage,
  handlePageTurn,
  showingModal,
  setFullscreenMapIsOpen,
}) {
  const renderButtons = () => {
    return pages.map(({ label, number }, i) => {
      let buttonStyle = { ...styles.base };
      let labelStyle = { ...styles.label };
      if (number === currentPage) {
        buttonStyle.background = "#F2F2F2";
        labelStyle.marginLeft = "calc(1em - 6px)";
      }

      return (
        <nav style={buttonStyle} onClick={() => handleGoToPage(number)} key={i}>
          {number === currentPage && <div style={styles.verticalLine} />}
          <p style={labelStyle}>{label}</p>
        </nav>
      );
    });
  };
  return (
    <CollapsibleMenu
      handlePageTurn={handlePageTurn}
      showingModal={showingModal}
      setFullscreenMapIsOpen={setFullscreenMapIsOpen}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {renderButtons()}
      </div>
    </CollapsibleMenu>
  );
}

export default Menu;

const styles = {
  base: {
    height: "3em",
    border: "none",
    display: "flex",
    alignItems: "center",
    background: "#fff",
  },
  verticalLine: {
    height: "100%",
    width: "6px",
    background: "-webkit-linear-gradient(45deg, #336699, #33ccff)",
  },
  label: { marginLeft: "1em", fontSize: "16px" },
};
