import axios from "axios";

export const getPins = (state = "", next) => {
  return new Promise(async (resolve, reject) => {
    const body = next
      ? { next }
      : { value: state.toUpperCase(), type: "state" };
    try {
      const response = await axios.post(
        "https://api.momentumsolar.io/crm_micro/installed_map",
        body
      );
      resolve(response.data);
    } catch (error) {
      console.error(error);
      reject([]);
    }
  });
};
