import React from "react";
import LabelCard from "./LabelCard";

import Quote from "./Quote";
import Stats from "./Stats";

function Layout1({
  installData,
  state,
  usePageHeader,
  setCustomerQuote,
  setShowingModal,
}) {
  const { photo, customer } = installData;

  const showSmallVersion = state === "NJ" && window.innerWidth < 1350;
  return (
    <div className="pageWrapper">
      <div
        style={{
          height: showSmallVersion ? "55%" : "45%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="pageContent" style={styles.content}>
          <div style={styles.itemsWrapper}>
            <div
              style={{
                ...styles.item,
                minWidth: showSmallVersion ? "60%" : "55%",
                paddingRight: "5%",
              }}
            >
              <Quote
                customer={customer}
                setCustomerQuote={setCustomerQuote}
                setShowingModal={setShowingModal}
              />
            </div>
            <div style={styles.item}>
              <Stats installData={installData} />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "100%",
          maxHeight: showSmallVersion ? "45%" : "55%",
          overflow: "hidden",
        }}
      >
        {usePageHeader && (
          <div style={styles.labelWrapper}>
            <LabelCard
              style={{ paddingTop: window.innerWidth > 900 ? "1.5em" : "1em" }}
            />
          </div>
        )}
        <img src={photo} alt={state + " Solar Home"} style={styles.img} />
      </div>
    </div>
  );
}

export default Layout1;

const styles = {
  itemsWrapper: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // background: "blue",
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "50%",
  },
  img: { height: "100%", width: "100%", bottom: 0, objectFit: "cover" },
  labelWrapper: {
    position: "absolute",
    bottom: 0,
    height: "25%",
    marginLeft: window.innerWidth > 900 ? "1.5em" : "1em",
    background: "white",
    display: "flex",
    justifyContent: "flex-start",
  },
};
