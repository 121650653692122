import NY1 from "../../assets/statePhotos/NY Install 1.png";
import NY2 from "../../assets/statePhotos/NY Install 2.png";
import NY3 from "../../assets/statePhotos/NY Install 3.png";
import NY4 from "../../assets/statePhotos/NY Install 4.png";

const NY = {
  coordinates: {
    center: { lat: 40.8919201503159, lng: -73.7 },
    zoom: 9.5,
  },
  fullName: "New York",
  installs: [
    {
      photo: NY1,
      panels: "12 Q Cell 340W",
      inverters: "12 Enphase IQ-7",
      production: "5230",
      customer: {
        quote:
          "Wonderful experience from start to finish. The sales rep did a great job explaining the entire process in a way I was able to understand. We discussed the many benefits of solar energy from saving money to saving the planet! The installers were fantastic as well. They were polite and did a great job! I was so pleased that I bought the crew lunch! I would highly recommend Momentum Solar to anyone who is looking to make the switch!",
        name: "Daniel K.",
        location: "Brentwood, NY",
        date: "May 2021",
      },
      layout: 3,
    },
    {
      photo: NY2,
      panels: "28 Q Cell 340W",
      inverters: "28 Enphase IQ-7+",
      production: "1162",
      customer: {
        quote:
          "From the beginning when we went over the Information on what going solar can save me to the day of installation, it was easy and professional. The day of the installation they checked periodically to make sure everything was fine inside the house and cleaned up after themselves, no mess left behind. I’ve already recommended friends to call them.",
        name: "Joseph J.",
        location: "Plainview, NY",
        date: "August 2021",
      },
      layout: 1,
    },
    {
      photo: NY3,
      panels: "9 Q Cell 380W",
      inverters: "9 Enphase IQ-7+",
      production: "4792",
      customer: {
        quote:
          "I was impressed that the technician was on time. He was very informative and pleasant. Even the installers were there before time waiting to start the job. Everything happened so fast that I couldn’t believe it.",
        name: "Guilane P.",
        location: "Mount Vernon, NY",
        date: "October 2021",
      },
      layout: 1,
    },
    {
      photo: NY4,
      panels: "24 Q Cell 380W",
      inverters: "24 Enphase IQ-7+",
      production: "10120",
      customer: {
        quote:
          "They have been extremely professional, courteous, and helpful. The crew that installed our system was wonderful. I highly recommend Momentum Solar if you are considering solar panels.",
        name: "Kim C.",
        location: "Village of Pelham, NY",
        date: "July 2021",
      },
      layout: 2,
    },
  ],
  energy: [
    {
      quote: "We’re in a difficult time for consumers.",
      source: "nbcnews.com",
      href: "https://www.nbcnews.com/",
    },
    {
      quote:
        "Renewable energy doesn’t have that volatile [pricing] stuff built into it because the fuel is free.",
      source: "thecity.nyc",
      href: "https://www.thecity.nyc/",
    },
    {
      quote:
        "Energy prices are volatile and can be affected by factors such as weather, demand and economic trends",
      source: "nysfocus.com",
      href: "https://www.nysfocus.com/",
    },
  ],
};

export default NY;
