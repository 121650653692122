import TX1 from "../../assets/statePhotos/TX Install 1.png";
import TX2 from "../../assets/statePhotos/TX Install 2.png";
import TX3 from "../../assets/statePhotos/TX Install 3.png";
import TX4 from "../../assets/statePhotos/TX Install 4.png";

const TX = {
  coordinates: {
    center: { lat: 32.03972995431194, lng: -99.2558396538978 },
    zoom: 6,
  },
  fullName: "Texas",
  installs: [
    {
      photo: TX1,
      panels: "24 Q Cell 380W",
      inverters: "24 Enphase IQ-7+",
      production: "14363 kW",
      customer: {
        quote:
          "We had a great experience with our solar panel installation. Momentum Solar’s staff is friendly, respectful and very professional! The process went without a hitch and we received constant updates about our project. We recommend working with Momentum Solar. Their prices are very reasonable and their customer service is fantastic.",
        name: "Cassandra A.",
        location: "Austin, TX",
        date: "July 2021",
      },
      layout: 1,
    },
    {
      photo: TX2,
      panels: "20 Q Cell 380W",
      inverters: "20 Enphase IQ-7+",
      production: "11880 kW",
      customer: {
        quote:
          "I appreciate the considerate, professional way of dealing with the Momentum Solar team. They make sure not to say no to the customers if they are short on budget. They made a flexible payment plan for me and installed the best quality solar panels. I am now enjoying a bill-free life.",
        name: "Chloe C.",
        location: "Dallas, TX",
        date: "July 2021",
      },
      layout: 1,
    },
    {
      photo: TX3,
      panels: "13 Q Cell 380W",
      inverters: "13 Enphase IQ-7+",
      production: "6333 kW",
      customer: {
        quote:
          "I couldn’t have had a better experience than the one I had with Momentum Solar. Our representative was very knowledgeable and personable. He made me feel comfortable and excited throughout the whole process. Next time I want solar panels, I will be going to them. Thanks!",
        name: "Alicia C.",
        location: "San Antonio, TX",
        date: "March 2021",
      },
      layout: 1,
    },
    {
      photo: TX4,
      panels: "21 Q Cell 340W",
      inverters: "21 Enphase IQ-7",
      production: "10301 kW",
      customer: {
        quote:
          "I recommend Momentum Solar to anyone who is looking for solar panels installation. They truly value their customer’s time and money; they came at the promised time and the installation was quick. The panels were great quality and had the right wattage for my home.",
        name: "Marsha D.",
        location: "Houston, TX",
        date: "December 2021",
      },
      layout: 2,
    },
  ],
  energy: [
    {
      quote:
        "If you’re searching for cheap electricity contracts, beware. Prices on PowerToChoose.org are very high, and the Oncor charge jumped 19% too.",
      source: "dallasnews.com",
      href: "https://www.dallasnews.com/",
    },
    {
      quote:
        "Your bills, that will already be higher because of the bitter cold, will stay that way beyond winter.",
      source: "khou.com",
      href: "https://www.khou.com/",
    },
    {
      quote:
        "After unusual icy weather left millions of Texans without power, some are facing another crisis: Sky-high electricity bills.",
      source: "apnews.com",
      href: "https://apnews.com/",
    },
  ],
};

export default TX;
