import React from "react";

import { motion } from "framer-motion";

const topVariants = {
  open: {
    top: "calc(50% - 1.5px)",
    transform: "rotate(-135deg)",
  },
  closed: { top: "calc(25% - 1.5px)" },
};

const middleVariants = {
  open: {
    opacity: 0,
    top: "calc(50% - 1.5px)",
  },
  closed: { top: "calc(50% - 1.5px)", opacity: 1 },
};

const bottomVariants = {
  open: {
    top: "calc(50% - 1.5px)",
    transform: "rotate(135deg)",
  },
  closed: { top: "calc(75% - 1.5px)" },
};

const transition = {
  duration: 1,
  ease: "backInOut",
};

function MenuIcon({ isOpen, style = {}, color = "#A5A5A5" }) {
  return (
    <div
      style={{
        position: "relative",
        height: "24px",
        aspectRatio: 1,
        ...style,
      }}
      className="menuIcon"
    >
      <motion.div
        style={{ ...lineStyle, background: color }}
        animate={isOpen ? "open" : "closed"}
        variants={topVariants}
        transition={transition}
      />
      <motion.div
        style={{ ...lineStyle, background: color }}
        animate={isOpen ? "open" : "closed"}
        variants={middleVariants}
        transition={transition}
      />
      <motion.div
        style={{ ...lineStyle, background: color }}
        animate={isOpen ? "open" : "closed"}
        variants={bottomVariants}
        transition={transition}
      />
    </div>
  );
}

export default MenuIcon;

const lineStyle = {
  minWidth: "100%",
  minHeight: "3px",
  borderRadius: "50px",
  position: "absolute",
};
