import NJ1 from "../../assets/statePhotos/NJ Install 1.png";
import NJ2 from "../../assets/statePhotos/NJ Install 2.png";
import NJ3 from "../../assets/statePhotos/NJ Install 3.png";
import NJ4 from "../../assets/statePhotos/NJ Install 4.png";

const NJ = {
  coordinates: {
    center: { lat: 40.579148010103744, lng: -74.4125982204122 },
    zoom: 11,
  },
  fullName: "New Jersey",
  installs: [
    {
      photo: NJ1,
      panels: "43 Q Cell 340W",
      inverters: "43 Enphase IQ-7",
      production: "17943",
      customer: {
        quote:
          "My family and I had a great experience with these guys through the whole installation process. My sales rep was incredibly helpful and knowledgeable. He was able to work with me on scheduling times to go over paperwork and signatures even if it was after normal business hours. The guys that showed up to do the installation a few weeks later were quick and efficient. They showed up around 8 AM and were done before noon. I noticed my electric usage was down almost immediately and I’ve even started banking power! All in all, extremely happy with the layout and how clean it looks on my house. And the fact that I’m already generating enough power to starting banking for summer!",
        name: "Nicole P.",
        location: "South Plainfield, NJ",
        date: "November 2021",
      },
      layout: 1,
    },
    {
      photo: NJ2,
      panels: "18 Q Cell 315W",
      inverters: "18 Enphase IQ-7",
      production: "6189",
      customer: {
        quote:
          "Salesman was very professional. Site assessment was thorough. Partial roof replacement was suggested. It took only one day to replace the roof and another day to install the panels. Workers seemed efficient and cleaned around the house after the installation. We are happy with the process. Now it’s time to enjoy the green energy. Thank you Momemtum Solar!",
        name: "Jim F.",
        location: "Warren, NJ",
        date: "June 2021",
      },
      layout: 2,
    },
    {
      photo: NJ3,
      panels: "20 Q Cell 340W",
      inverters: "20 Enphase IQ-7",
      production: "7893",
      customer: {
        quote:
          "My solar panels are installed and everything is working great. The sales representative that came out to my house was super friendly and informative. She took the time to answer all my solar-related questions and explain the whole process. I would recommend Momentum Solar to anyone looking into installing solar panels on their home and saving money on energy bills.",
        name: "Willie M.",
        location: "Cherry Hill, NJ",
        date: "June 2021",
      },
      layout: 3,
    },
    {
      photo: NJ4,
      panels: "18 Q Cell 380W",
      inverters: "18 Enphase IQ-7+",
      production: "9354",
      customer: {
        quote:
          "Before every step, someone would call me and tell me when and who would do it. They have a very nice workflow. They are very efficient and after a short wait time, the project starts. The install staff are friendly and pay attention to protecting the owner’s property.",
        name: "Zh M.",
        location: "Atco, NJ",
        date: "July 2021",
      },
      layout: 1,
    },
  ],
  energy: [
    {
      quote: "JCP&L electric bills set to go up...",
      source: "App.com",
      href: "https://www.app.com/",
    },
    {
      quote:
        "Atlantic City Electric seeks rate increase to pay for system upgrades",
      source: "Philly.com",
      href: "https://www.inquirer.com/",
    },
    {
      quote:
        "New Jersey customers can expect to pay more as power prices spike in capacity auction",
      source: "NJSpotlight.com",
      href: "https://www.njspotlightnews.org/",
    },
  ],
};

export default NJ;
