import React from "react";

import { motion } from "framer-motion";

const backgroundVariants = {
  visible: { opacity: [0, 0, 1], y: 0 },
  hidden: { opacity: [1, 0, 0], y: "-110vh" },
};

const transition = {
  duration: 0.8,
  times: [0, 0.5, 1],
};

function ModalBackground({ children, showingModal }) {
  return (
    <motion.div
      variants={backgroundVariants}
      animate={showingModal ? "visible" : "hidden"}
      transition={transition}
      initial="hidden"
      style={{
        height: "100%",
        width: "100%",
        position: "fixed",

        backdropFilter: "blur(10px)",
        background: "rgba(0,0,0, 0.2)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      {children}
    </motion.div>
  );
}

export default ModalBackground;
