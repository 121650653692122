import React, { useEffect, useState } from "react";

import ModalBackground from "./ModalBackground";
import QuoteModal from "./QuoteModal";
import ScreenSizeModal from "./ScreenSizeModal";
import WhichStateModal from "./WhichStateModal";

const MIN_SCREEN_WIDTH = 900;

function ModalManager({
  stateAbbr,
  setStateAbbr,
  setShowingModal,
  showingModal,
  customerQuote,
}) {
  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );

  const [overrideValidSize, setOverrideValidSize] = useState(
    window.innerWidth > MIN_SCREEN_WIDTH
  );

  const checkIsValidSize = () => {
    setIsLandscape(window.innerWidth > window.innerHeight);
    setOverrideValidSize(window.innerWidth > MIN_SCREEN_WIDTH);
  };
  window
    .matchMedia("(orientation: portrait)")
    .addEventListener("change", checkIsValidSize);
  window
    .matchMedia("(orientation: landscape)")
    .addEventListener("change", checkIsValidSize);

  document.addEventListener("resize", checkIsValidSize);

  useEffect(() => {
    if ((isLandscape || overrideValidSize) && stateAbbr) {
      setShowingModal(false);
    } else {
      setShowingModal(true);
    }
  }, [isLandscape, overrideValidSize, stateAbbr]);

  return (
    <ModalBackground showingModal={showingModal}>
      <div
        style={{
          background: "#F4F4F4",
          padding: "50px",
          margin: "1em",
          maxWidth: "400px",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!isLandscape && !overrideValidSize ? (
          <ScreenSizeModal setOverrideValidSize={setOverrideValidSize} />
        ) : customerQuote ? (
          <QuoteModal
            customer={customerQuote}
            setShowingModal={setShowingModal}
          />
        ) : !stateAbbr ? (
          <WhichStateModal setStateAbbr={setStateAbbr} />
        ) : null}
      </div>
    </ModalBackground>
  );
}

export default ModalManager;
