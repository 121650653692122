import PA1 from "../../assets/statePhotos/PA Install 1.png";
import PA2 from "../../assets/statePhotos/PA Install 2.png";
import PA3 from "../../assets/statePhotos/PA Install 3.png";
import PA4 from "../../assets/statePhotos/PA Install 4.png";

const PA = {
  coordinates: {
    center: { lat: 40.293256990326, lng: -75.63984186218767 },
    zoom: 8.5,
  },
  fullName: "Pennsylvania",
  installs: [
    {
      photo: PA1,
      panels: "12 Q Cell 380W",
      inverters: "12 Enphase IQ-7+",
      production: "5153",
      customer: {
        quote:
          "My husband and I have had the solar panels by Momentum Solar for more than six months now, and $80 less every month is worth it.  Momentum Solar is an awesome company to work with. Our sales  rep was very helpful and prompt to address all issues.",
        name: "Fredda A.",
        location: "Lancaster, PA",
        date: "October 2021",
      },
      layout: 1,
    },
    {
      photo: PA2,
      panels: "40 Q Cell 380W",
      inverters: "40 Enphase IQ-7",
      production: "15429",
      customer: {
        quote:
          "I can’t believe that we are saving this much after having these professionals install solar panels on our home. Momentum Solar is a reliable company. We are grateful to our sales representative, Henry, who took the time to properly educate us about the app to track the progress of the installation works.",
        name: "Mabel M.",
        location: "New Holland, PA",
        date: "June 2021",
      },
      layout: 2,
    },
    {
      photo: PA3,
      panels: "10 Q Cell 340W",
      inverters: "10 Enphase IQ-7",
      production: "4269",
      customer: {
        quote:
          "I highly recommend Momentum Solar to those who plan to transition to solar energy at home because they work with speed and expertise. ",
        name: "Rochelle B.",
        location: "Pottstown, PA",
        date: "May 2021",
      },
      layout: 3,
    },
    {
      photo: PA4,
      panels: "18 Q Cell 340W",
      inverters: "18 Enphase IQ-7",
      production: "5893",
      customer: {
        quote:
          "So far, so GREAT! The entire staff from sales, roofers, and installation team have all been professional, prompt and informative!",
        name: "Stephen S.",
        location: "Hershey, PA",
        date: "August 2021",
      },
      layout: 1,
    },
  ],
  energy: [
    {
      quote:
        "Consumers have very much enjoyed the benefit of historically low prices the last couple of years, but in 2021, their prices are going to be higher.",
      source: "pennlive.com",
      href: "https://www.pennlive.com/",
    },
    {
      quote:
        "State’s electric distribution companies are increasing rates between 2% and 30% or non-shopping customers.",
      source: "Abc27.com",
      href: "https://www.abc27.com/",
    },
    {
      quote:
        "Higher electricity rates could be especially painful for the 71,000 households in Lancaster County.",
      source: "Lancasteronline.com",
      href: "https://lancasteronline.com/",
    },
  ],
};

export default PA;
