import React from "react";

function Quote({ customer, setCustomerQuote, setShowingModal }) {
  const { name, quote, location, date } = customer;

  return (
    <>
      <div style={styles.wrapper}>
        <h3 style={styles.name}>{name}</h3>
        <p className="quote-text">{quote}</p>
        {window.innerWidth <= 900 && (
          <p
            style={styles.readMore}
            onClick={(e) => {
              e.stopPropagation();
              setCustomerQuote(customer);
              setShowingModal(true);
            }}
          >
            read more
          </p>
        )}
        <div style={styles.horizontalLine} />
        <div style={styles.footer}>
          <h4 style={styles.location}>{location}</h4>
          {/* <div style={styles.verticalLine} /> */}
          {/* <h4 style={styles.date}>{date}</h4> */}
        </div>
      </div>
    </>
  );
}

export default Quote;

const styles = {
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    flexDirection: "column",
  },
  name: {
    color: "#33ccff",
    fontSize: window.innerWidth > 900 ? "1.2rem" : 11,
    marginBottom: window.innerWidth > 900 ? "4px" : "0px",
  },
  horizontalLine: {
    height: window.innerWidth > 900 ? "clamp(5px, 0.3rem,10px)" : 2,
    width: "90%",
    background: "-webkit-linear-gradient(45deg, #33ccff, #336699)",
    margin: "4px 0",
  },
  footer: {
    display: "flex",
  },
  location: {
    color: "#AFB0B3",
    fontFamily: "Gotham",
    // borderRight: "2px solid #336699",
    marginRight: "0.5em",
    paddingRight: "0.5em",
    fontSize: window.innerWidth > 900 ? "0.8rem" : 8,
  },
  date: {
    color: "#33ccff",
    fontFamily: "Gotham",
    fontSize: window.innerWidth > 900 ? "0.8rem" : 8,
  },

  readMore: {
    color: "#336699",
  },
};
