import React from "react";

import Background from "../../assets/ReferralBG.png";
import Overlay from "../../assets/ReferralOverlay.png";

function ReferralProgram() {
  return (
    <div className="pageWrapper">
      <div className="pageContent" style={styles.content}>
        <div className="card">
          <div>
            <h2>Learn about our</h2>
            <h1>referral program</h1>
          </div>
          <p style={styles.text}>
            With our competitive referral program, you can earn extra cash just
            for helping your family, friends and neighbors start their solar
            journey.
          </p>
        </div>
      </div>
      <img src={Background} alt="Solar Offset Background" style={styles.img} />
      <img
        src={Overlay}
        alt="Solar Offset Background"
        style={styles.overlayImg}
      />
    </div>
  );
}

export default ReferralProgram;

const styles = {
  content: {
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    background: "white",
    width: "36%",
    marginTop: "0px",
  },
  text: {
    marginTop: "1em",
  },
  img: {
    position: "absolute",
    height: "100%",
    zIndex: 0,
  },
  overlayImg: { position: "absolute", height: "100%", zIndex: 100 },
};
