import NV1 from "../../assets/statePhotos/NV Install 1.png";
import NV2 from "../../assets/statePhotos/NV Install 2.png";
import NV3 from "../../assets/statePhotos/NV Install 3.png";
import NV4 from "../../assets/statePhotos/NV Install 4.png";

const NV = {
  coordinates: {
    center: { lat: 36.166133446584446, lng: -115.1455695038422 },
    zoom: 9,
  },
  fullName: "Nevada",
  installs: [
    {
      photo: NV1,
      panels: "9 Q Cell 340W",
      inverters: "9 Enphase IQ-7",
      production: "4970",
      customer: {
        quote:
          "I got solar panels from Momentum Solar to power all of my appliances. They are very reliable and highly responsive. Our project manager responds promptly to my calls and ensures that everything works perfectly.",
        name: "Jacqueline S.",
        location: "Las Vegas, NV",
        date: "April 2021",
      },
      layout: 1,
    },
    {
      photo: NV2,
      panels: "10 Q Cell 380W",
      inverters: "10 Enphase IQ-7+",
      production: "6312",
      customer: {
        quote:
          "I had them install my solar when I was out of town. Their website makes the entire solar installation process easy. I was able to pay and book aninstallation date online. They were respectful of my property and worked well without any supervision.",
        name: "Jan N.",
        location: "Henderson, NV",
        date: "December 2021",
      },
      layout: 2,
    },
    {
      photo: NV3,
      panels: "8 Q Cell 380W",
      inverters: "8 Enphase IQ-7+",
      production: "5615",
      customer: {
        quote:
          "I have many electrical appliances in my home, and with a large family, utility bills were hitting the roof. I installed solar to help reduce the bills, and the difference has been more than I had anticipated. Momentum Solar is one of the few companies I can confidently vouch for.",
        name: "Ramona M.",
        location: "Las Vegas, NV",
        date: "August 2021",
      },
      layout: 3,
    },
    {
      photo: NV4,
      panels: "8 Q Cell 380W",
      inverters: "8 Enphase IQ-7+",
      production: "5745",
      customer: {
        quote:
          "Having Momentum install solar panels in my home has been an all-around win. My utility bills have decreased, and the value of my property has gone up. It has been a year and I can power everything in my house AND still have  extra power capacity.",
        name: "Tammy W.",
        location: "Las Vegas, NV",
        date: "June 2021",
      },
      layout: 1,
    },
  ],
  energy: [
    {
      quote:
        "Supply and demand. I feel like you have to pay for it if you want to keep your feet warm. ",
      source: "mynews4.com",
      href: "https://mynews4.com/",
    },
    {
      quote:
        "The U.S. Department of Energy predicts that natural‐gas prices could more than double by 2040.",
      source: "npri.org",
      href: "https://www.npri.org/",
    },
    {
      quote:
        "Electric bills for single-family homes would increase by $5.25 a month or 4.8 percent over the course of nine months.",
      source: "carsonnow.org",
      href: "https://carsonnow.org/",
    },
  ],
};

export default NV;
