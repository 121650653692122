import React from "react";

import Background from "../../assets/WhiteGloveServiceBG.png";

function WhiteGloveService() {
  return (
    <div className="pageWrapper">
      <div className="pageContent" style={styles.content}>
        <div
          className="card"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div style={{ alignSelf: "flex-start" }}>
            <h2>White-glove</h2>
            <h1>service</h1>
          </div>
          <div style={{ marginTop: "0.5em" }}>
            <h4>learn about some of our</h4>
            <h3>beautiful installs</h3>
          </div>
          <p style={styles.text}>
            Customers across the country are making the switch to solar, but
            each home is unique in its needs and design.
            <br />
            <br />
            See some of our recent installs to learn more about how your home
            will benefit.
          </p>
        </div>
      </div>
      <img src={Background} alt="Solar Offset Background" style={styles.img} />
    </div>
  );
}

export default WhiteGloveService;

const styles = {
  content: {
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    background: "white",
    width: "50%",
    marginTop: "0px",
  },
  text: {
    marginTop: "1em",
  },
  img: {
    position: "absolute",
    width: "100%",
    zIndex: 0,
    top: "-4%",
  },
};
