import React from "react";
// import { ReactComponent as USMap } from "../../assets/USMapPins.svg";
import { ReactComponent as USMap } from "../../assets/USMap.svg";
import { ReactComponent as USPins } from "../../assets/USPins.svg";
import AnimatedPins from "../../Components/AnimatedPins";
function GainingMomentum2({ currentPage, hoveredCity }) {
  return (
    <div
      className="pageWrapper"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "relative",
          width: "90%",
          height: "90%",
        }}
      >
        <USMap
          width={"100%"}
          height="100%"
          style={{ maxHeight: "100%", position: "absolute" }}
        />

        {currentPage === 2 && <AnimatedPins hoveredCity={hoveredCity} />}
      </div>
    </div>
  );
}

export default GainingMomentum2;
