import AZ from "./AZ";
import CA from "./CA";
import CT from "./CT";
import FL from "./FL";
import GA from "./GA";
import MA from "./MA";
import NJ from "./NJ";
import NV from "./NV";
import NY from "./NY";
import PA from "./PA";
import TX from "./TX";

const statesData = {
  AZ,
  CA,
  CT,
  FL,
  GA,
  MA,
  NJ,
  NV,
  NY,
  PA,
  TX,
};

export default statesData;
