import CA1 from "../../assets/statePhotos/CA Install 1.png";
import CA2 from "../../assets/statePhotos/CA Install 2.png";
import CA3 from "../../assets/statePhotos/CA Install 3.png";
import CA4 from "../../assets/statePhotos/CA Install 4.png";

const CA = {
  coordinates: {
    center: { lat: 36.62296616590436, lng: -119.83057099337543 },
    zoom: 7,
  },
  fullName: "California",
  installs: [
    {
      photo: CA1,
      panels: "12 Q Cell 315W",
      inverters: "12 Enphase IQ-7",
      production: "6554",
      customer: {
        quote:
          "Momentum Solar is quite a reliable company. I got my Solar panels fixed by them some time ago and I have not faced any issues. The team members were very friendly while also being on professional grounds. Hats off to them!",
        name: "Sandra P.",
        location: "Orange, CA",
        date: "December 2021",
      },
      layout: 3,
    },
    {
      photo: CA2,
      panels: "12 Q Cell 340W",
      inverters: "12 Enphase IQ-7",
      production: "5291",
      customer: {
        quote:
          "I heard about solar panels and decided to book an appointment with Momentum Solar. They did a fantastic job!",
        name: "Kristen W.",
        location: "Santa Ana, CA",
        date: "September 2021",
      },
      layout: 1,
    },
    {
      photo: CA3,
      panels: "39 Q Cell 340W",
      inverters: "39 Enphase IQ-7",
      production: "20221",
      customer: {
        quote:
          "The staff was extremely generous while helping us with our panels. They did such a spectacular job that I asked them to do the same for my sister’s house. She was more than happy.",
        name: "Julia C.",
        location: "Costa Mesa, CA",
        date: "August 2021",
      },
      layout: 1,
    },
    {
      photo: CA4,
      panels: "42 Q Cell 315W",
      inverters: "42 Enphase IQ-7",
      production: "18730",
      customer: {
        quote:
          "I am quite fond of the work completed by the expert technicians of Momentum Solar. They did an excellent job with satisfactory results. I will use their services for every property I will buy from now on.",
        name: "Cathy B.",
        location: "Fullerton, CA",
        date: "February 2021",
      },
      layout: 1,
    },
  ],
  energy: [
    {
      quote:
        "California’s power grid is straining to keep up wits demand as climate changs affects electricity usage.",
      source: "latimes.com",
      href: "https://www.latimes.com/",
    },
    {
      quote:
        "Residential customers will pay an estimated 9% more starting March 1, an average increase of about $14.",
      source: "sfgate.com",
      href: "https://www.sfgate.com/",
    },
    {
      quote:
        "Surging natural gas prices – along with the ongoing need to strengthen safety, reliability and sustainability and provide public benefit programs – are creating challenges.",
      source: "sdgenews.com",
      href: "https://www.sdgenews.com/",
    },
  ],
};

export default CA;
