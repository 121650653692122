import "./App.css";
import { useState } from "react";

import { BrowserRouter as Router } from "react-router-dom";

import Lookbook from "./Pages/Lookbook";

function App() {
  const [showingModal, setShowingModal] = useState(true);

  return (
    <div
      className="App"
      style={{
        overflow: showingModal ? "hidden" : "auto",
      }}
    >
      <Router>
        <Lookbook
          setShowingModal={setShowingModal}
          showingModal={showingModal}
        />
      </Router>
    </div>
  );
}

export default App;
