import React from "react";
import { ReactComponent as CleanEnergy } from "../../assets/CleanEnergy.svg";
function CleanVsDirty2() {
  return (
    <div className="pageWrapper" style={styles.background}>
      <div className="pageContent" style={styles.content}>
        <div style={styles.titleWrapper}>
          <h2>Clean</h2>
          <h1>energy</h1>
        </div>

        <p style={styles.text}>A Brighter, Price-Protected Future</p>
        <p style={styles.text}>
          Enjoy your monthly savings with a peace of mind
        </p>
        <p style={styles.text}>
          By switching to solar, you are helping build a brighter future by{" "}
          <br />
          producing generations of clean electricity
        </p>
        <CleanEnergy style={styles.svg} />
      </div>
    </div>
  );
}

export default CleanVsDirty2;

const styles = {
  background: {
    background: "linear-gradient(#fff, #E8FFEE)",
  },
  titleWrapper: {
    alignSelf: "flex-end",
    textAlign: "end",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "flex-end",
    position: "relative",
    textAlign: "end",
  },
  text: {
    fontSize: window.innerWidth > 800 ? "0.9rem" : 12,
    color: "#707070",
    marginTop: "1em",
  },
  svg: {
    position: "absolute",
    width: "100%",
    height: "auto",
    bottom: "-5",
  },
};
