import { motion } from "framer-motion";
import PageNumber from "./PageNumber";

function Pages({
  isFlipped = false,
  frontComponent,
  backComponent,
  pageNumber,
  goingForward,
  currentPage,
  handlePageTurn,
}) {
  const transitionDuration = 0.8; //Seconds
  // Finds the zIndex value given the page state. It
  const getZIndexValue = (flipped = isFlipped) => {
    let directionModifier = goingForward && currentPage > pageNumber ? 2 : 0;
    let result = flipped
      ? pageNumber + directionModifier
      : currentPage + (currentPage - pageNumber);
    return result + 100; // 100 is added to make sure there are no visible negative pages.
  };

  // This is a Framer Motion convention. Variants is an object which contains animation states.
  const frontVariants = {
    notFlipped: { rotateY: 0, zIndex: getZIndexValue(false) },
    flipped: { rotateY: -181, zIndex: getZIndexValue(true) },
  };
  const backVariants = {
    notFlipped: {
      rotateY: 0,
      zIndex: getZIndexValue(false) - 1,
      duration: 0.1,
    },
    flipped: { rotateY: -180, zIndex: getZIndexValue(true) - 1 },
  };

  // After fiddling, these transition options looks best so far.
  const transition = {
    duration: transitionDuration,
    ease: "linear",
    zIndex: { delay: transitionDuration / 2 },
  };

  return (
    <div className={`page`}>
      <motion.div
        className={`front`}
        variants={frontVariants}
        animate={isFlipped ? "flipped" : "notFlipped"}
        transition={transition}
      >
        <div className="content" onClick={(e) => handlePageTurn(1, e)}>
          {frontComponent}
        </div>
        {pageNumber !== 0 && pageNumber !== 12 && (
          <PageNumber number={pageNumber * 2} isBack={true} />
        )}
      </motion.div>
      <motion.div
        className={`back`}
        variants={backVariants}
        animate={isFlipped ? "flipped" : "notFlipped"}
        transition={transition}
      >
        <div className="content" onClick={(e) => handlePageTurn(-1)}>
          {backComponent}
        </div>
        {pageNumber <= 10 && <PageNumber number={pageNumber * 2 + 1} />}
      </motion.div>
    </div>
  );
}

export default Pages;
