import GA1 from "../../assets/statePhotos/GA Install 1.png";
import GA2 from "../../assets/statePhotos/GA Install 2.png";
import GA3 from "../../assets/statePhotos/GA Install 3.png";
import GA4 from "../../assets/statePhotos/GA Install 4.png";

const GA = {
  coordinates: {
    center: { lat: 33.6837682438342, lng: -84.01831885394476 },
    zoom: 8,
  },
  fullName: "Georgia",
  installs: [
    {
      photo: GA1,
      panels: "28 Q Cell 340W",
      inverters: "28 Enphase IQ-7",
      production: "11788",
      customer: {
        quote:
          "Momentum is THE BEST solar company I have ever come across! The are very professional and on top of everything. They were there 100% of the time when ever I need anything or had any questions. The best decision we have made for our home!",
        name: "Shina S.",
        location: "Morcross, GA",
        date: "September 2021",
      },
      layout: 1,
    },
    {
      photo: GA2,
      panels: "24 Q Cell 340W",
      inverters: "24 Enphase IQ-7",
      production: "10568",
      customer: {
        quote:
          "Great experience working with the Momentum Solar team at every step of the way… from the project manager who worked with me during every stage of the process to the installation crew who were some of the most polite workers I have seen.",
        name: "Deepak P.",
        location: "Athens, GA",
        date: "September 2021",
      },
      layout: 2,
    },
    {
      photo: GA3,
      panels: "15 Q Cell 340W",
      inverters: "15 Enphase IQ-7",
      production: "6588",
      customer: {
        quote:
          "I am very impressed on how fast they executed everything. I honestly thought this process was going to take weeks and I mentally prepared myself for a long, drawn out process. To my surprise, it was a quick process. Great company!",
        name: "Kristal L.",
        location: "Tucker, GA",
        date: "May 2021",
      },
      layout: 1,
    },
    {
      photo: GA4,
      panels: "28 Q Cell 380W",
      inverters: "28 Enphase IQ-7+",
      production: "13370",
      customer: {
        quote:
          "I installed solar panels for my home with Momentum and the entire journey has been smooth! Customer service has been impressive.",
        name: "Allendale, GA",
        location: "Lutz, GA",
        date: "June 2021",
      },
      layout: 1,
    },
  ],
  energy: [
    {
      quote:
        "Georgia Power customers are looking at a possible spike in their power bills.",
      source: "wjbf.com",
      href: "https://www.wjbf.com/",
    },
    {
      quote:
        "As you see, the price of natural gas goes up. You will expect naturally that the price of electricity will go up.",
      source: "wrdw.com",
      href: "https://www.wrdw.com/",
    },
    {
      quote:
        "Bills will rise again 2-2.5% in 2021 and an added 4.5-5% in 2022.",
      source: "wabe.org",
      href: "https://www.wabe.org/",
    },
  ],
};

export default GA;
