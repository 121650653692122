import MA1 from "../../assets/statePhotos/MA Install 1.png";
import MA2 from "../../assets/statePhotos/MA Install 2.png";
import MA3 from "../../assets/statePhotos/MA Install 3.png";
import MA4 from "../../assets/statePhotos/MA Install 4.png";

const MA = {
  coordinates: {
    center: { lat: 42.3577549882442, lng: -71.1011930625859 },
    zoom: 8.5,
  },
  fullName: "Massachusetts",
  installs: [
    {
      photo: MA1,
      panels: "28 Q Cell 340W",
      inverters: "28 Enphase IQ-7+",
      production: "9120",
      customer: {
        quote:
          "Their solar energy system helped me save thousands of dollars and gave me peace of mind knowing that we are getting cleaner, emission-free electricity.",
        name: "Fred C.",
        location: "Marlborough, MA",
        date: "January 2021",
      },
      layout: 3,
    },
    {
      photo: MA2,
      panels: "32 Q Cell 340W",
      inverters: "32 Enphase IQ-7",
      production: "12049",
      customer: {
        quote:
          "It was a pleasure dealing with Momentum Solar. In fact, I’m so impressed with the quality of their work that I wouldn’t hesitate to recommend them to my friends and family. Their solar industry knowledge is second only to their commitment to providing customers with the utmost value and service possible.",
        name: "Fannie D.",
        location: "Whitman, MA",
        date: "April 2021",
      },
      layout: 1,
    },
    {
      photo: MA3,
      panels: "12 Q Cell 340W",
      inverters: "12 Enphase IQ-7",
      production: "4283",
      customer: {
        quote:
          "I discovered that I wanted to put solar panels on my house, and after six months of study, I decided on Momentum. My study was correct to the millimeter. Their installers did a fantastic job without causing any damage to my roof.",
        name: "Sally S.",
        location: "Worchester, MA",
        date: "July 2021",
      },
      layout: 3,
    },
    {
      photo: MA4,
      panels: "27 Q Cell 340W",
      inverters: "27 Enphase IQ-7",
      production: "10325",
      customer: {
        quote:
          "I just want to say that our experience with Momentum Solar has been nothing but positive. We have called with questions a few times, and they’re always nice, knowledgeable and super helpful! Their crew went above and beyond to help us with our installation. I can’t say enough good things about this company, so thank you!",
        name: "Pauline J.",
        location: "Hanover, MA",
        date: "May 2021",
      },
      layout: 1,
    },
  ],
  energy: [
    {
      quote:
        "Your electric bill will be higher because of the increased demand for electricity and natural gas.",
      source: "bostonglobe.com",
      href: "https://www.bostonglobe.com/",
    },
    {
      quote:
        "The energy efficiency expert predicts about a 15% increase in electricity costs.",
      source: "masslive.com",
      href: "https://www.masslive.com/",
    },
    {
      quote: "Some of these rate increases will be substantial.",
      source: "consumerenergysolutions.com",
      href: "https://consumerenergysolutions.com/",
    },
  ],
};

export default MA;
