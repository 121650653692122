import React from "react";

import Encharge1 from "../../assets/Encharge1.png";
import Encharge2 from "../../assets/Encharge2.png";

import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0, x: 20 },
  visible: { opacity: 1, x: 0 },
};

const transition = {
  duration: 1,
  delay: 0.5,
  ease: "easeInOut",
};

function SystemUpgrades2({ currentPage }) {
  return (
    <div className="pageWrapper" style={styles.background}>
      <div className="pageContent" style={styles.content}>
        <div style={styles.headerWrapper}>
          <h2 style={styles.text}>See our other</h2>
          <h1>system upgrades</h1>
        </div>
        <p
          style={{
            ...styles.text,
            fontSize: window.innerWidth > 900 ? "inherit" : "12px",
            textAlign: "end",
          }}
        >
          Momentum Solar uses only the finest equipment to keep you worry free.
        </p>
        {(currentPage === 8 || currentPage === 7) && (
          <div style={{ display: "flex", height: "100%" }}>
            <div style={styles.item}>
              <motion.img
                initial={"hidden"}
                animate={currentPage === 8 ? "visible" : "hidden"}
                variants={variants}
                transition={transition}
                src={Encharge1}
                alt="Encharge cabinet one"
                style={styles.img}
              />
              <div style={styles.itemTextWrapper}>
                <motion.h5
                  initial={"hidden"}
                  animate={currentPage === 8 ? "visible" : "hidden"}
                  variants={variants}
                  transition={{ ...transition, delay: 0.6 }}
                  style={styles.header}
                >
                  IQ 10
                </motion.h5>
                <motion.p
                  initial={"hidden"}
                  animate={currentPage === 8 ? "visible" : "hidden"}
                  variants={variants}
                  transition={{ ...transition, delay: 0.7 }}
                  style={{ ...styles.text, margin: "1em 0" }}
                >
                  - 10.08 kWh Battery Unit
                </motion.p>
                <motion.p
                  initial={"hidden"}
                  animate={currentPage === 8 ? "visible" : "hidden"}
                  variants={variants}
                  transition={{ ...transition, delay: 0.8 }}
                  style={styles.text}
                >
                  - Offers more capacity without sacrificing flexibility and
                  scalability
                </motion.p>
              </div>
            </div>

            <div style={styles.item}>
              <motion.img
                initial={"hidden"}
                animate={currentPage === 8 ? "visible" : "hidden"}
                variants={variants}
                transition={{ ...transition, delay: 1 }}
                src={Encharge2}
                alt="Encharge cabinet one"
                style={styles.img}
              />
              <div style={styles.itemTextWrapper}>
                <motion.h5
                  initial={"hidden"}
                  animate={currentPage === 8 ? "visible" : "hidden"}
                  variants={variants}
                  transition={{ ...transition, delay: 1.1 }}
                  style={{ ...styles.header, marginTop: "2em" }}
                >
                  IQ 3
                </motion.h5>
                <motion.p
                  initial={"hidden"}
                  animate={currentPage === 8 ? "visible" : "hidden"}
                  variants={variants}
                  transition={{ ...transition, delay: 1.2 }}
                  style={{ ...styles.text, margin: "1em 0" }}
                >
                  - 3.36 kWh Battery Unit
                </motion.p>
                <motion.p
                  initial={"hidden"}
                  animate={currentPage === 8 ? "visible" : "hidden"}
                  variants={variants}
                  transition={{ ...transition, delay: 1.3 }}
                  style={styles.text}
                >
                  - Includes four embedded gridforming microinverters with 1.28
                  kW power rating
                </motion.p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SystemUpgrades2;

const styles = {
  background: {
    background: "-webkit-linear-gradient( #464646, #141414)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  headerWrapper: {
    marginBottom: "1em",
    alignSelf: "flex-end",
    textAlign: "end",
  },
  header: { color: "#33ccff" },
  item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: "4em",
  },
  itemTextWrapper: {
    marginLeft: window.innerWidth > 900 ? "3em" : 0,
  },
  text: {
    color: "#fff",
    fontSize: window.innerWidth > 900 ? "12px" : "10px",
  },
  img: {
    width: "80%",
    justifySelf: "flex-end",
  },
};
