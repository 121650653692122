import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";

import Book from "../Components/Book";
import Menu from "../Components/Menu/Menu";
import Tutorial from "../Components/Tutorial";
import ModalManager from "../Components/Modals/ModalManager";

import Cover from "./01_Cover/Cover";
import About1 from "./02_About/About1";
import About2 from "./02_About/About2";
import GainingMomentum1 from "./03_GainingMomentum/GainingMomentum1";
import GainingMomentum2 from "./03_GainingMomentum/GainingMomentum2";
import WeAreMomentum1 from "./04_WeAreMomentum/WeAreMomentum1";
import WeAreMomentum2 from "./04_WeAreMomentum/WeAreMomentum2";
import CleanVsDirty1 from "./05_CleanVsDirty/CleanVsDirty1";
import CleanVsDirty2 from "./05_CleanVsDirty/CleanVsDirty2";
import BenefitsOfSolar from "./06_BenefitsOfSolar/BenefitsOfSolar";
import MaximizeSolarOffset from "./06_BenefitsOfSolar/MaximizeSolarOffset";
import CustomizedSystemDesign from "./07_CustomizedSystemDesign/CustomizedSystemDesign";
import WeMakeSolarBeautiful from "./07_CustomizedSystemDesign/WeMakeSolarBeautiful";
import FinestEquipment1 from "./08_FinestEquipment/FinestEquipment1";
import FinestEquipment2 from "./08_FinestEquipment/FinestEquipment2";
import SystemUpgrades1 from "./09_SystemUpgrades/SystemUpgrades1";
import SystemUpgrades2 from "./09_SystemUpgrades/SystemUpgrades2";
import ReferralProgram from "./10_ReferralProgram/ReferralProgram";
import WhiteGloveService from "./10_ReferralProgram/WhiteGloveService";
import Installs from "./11&12_Installs/Installs";
import MapPage from "./13_Map&BackCover/MapPage";
import BackCover from "./13_Map&BackCover/BackCover";

import statesData from "../Utils/states/index";
import { cleanStateParam } from "../Utils/helpers";
import FullscreenMap from "../Components/Modals/FullscreenMap";
import { getPins } from "../Utils/api";

function Lookbook({ setShowingModal, showingModal }) {
  const { pathname: stateParam } = useLocation();
  const [goingForward, setGoingForward] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [stateAbbr, setStateAbbr] = useState(cleanStateParam(stateParam));
  const [hoveredCity, setHoveredCity] = useState();
  const [customerQuote, setCustomerQuote] = useState();
  const [fullscreenMapIsOpen, setFullscreenMapIsOpen] = useState(false);
  const [userLocation, setUserLocation] = useState();
  const onPinsUpdatedRef = useRef(() => {});
  const pinsRef = useRef([]);

  const getPaginatedPins = async (state, pinsRef, next) => {
    try {
      const res = await getPins(state, next);
      if (res.value) {
        pinsRef.current = [...pinsRef.current, ...res.value];
        onPinsUpdatedRef.current();
      }
      if (res.next) {
        getPaginatedPins(state, pinsRef, res.next);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGoToPage = (targetPage) => {
    handlePageTurn(targetPage - currentPage);
  };

  const handlePageTurn = (n) => {
    const nextPage = currentPage + n;
    if (nextPage >= 0 && nextPage <= 12) {
      setGoingForward(n > 0);
      setCurrentPage(nextPage);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude: lat, longitude: lng } = pos.coords;
      setUserLocation({ lat, lng });
    });
  }, []);

  useEffect(() => {
    if (stateAbbr) {
      getPaginatedPins(stateAbbr, pinsRef);
    }
  }, [stateAbbr]);

  const stateData = statesData[stateAbbr] || statesData["NJ"]; // If there is no valid state, default to NJ

  return (
    <>
      <Menu
        currentPage={currentPage}
        handlePageTurn={handlePageTurn}
        handleGoToPage={handleGoToPage}
        showingModal={showingModal}
        setFullscreenMapIsOpen={setFullscreenMapIsOpen}
      />
      <ModalManager
        stateAbbr={stateAbbr}
        setStateAbbr={setStateAbbr}
        stateData={statesData[stateAbbr] || stateData}
        setShowingModal={setShowingModal}
        showingModal={showingModal}
        customerQuote={customerQuote}
      />
      {fullscreenMapIsOpen && (
        <FullscreenMap
          fullscreenMapIsOpen={fullscreenMapIsOpen}
          setFullscreenMapIsOpen={setFullscreenMapIsOpen}
          stateData={stateData}
          stateAbbr={stateAbbr}
          userLocation={userLocation}
          currentPage={currentPage}
          pinsRef={pinsRef}
          setOnPinsUpdated={(fn) => {
            onPinsUpdatedRef.current = fn;
          }}
        />
      )}
      <Tutorial
        currentPage={currentPage}
        showTutorial={
          (stateAbbr === "Other" || !!statesData[stateAbbr]) && !showingModal
        }
      />

      <Book
        currentPage={currentPage}
        goingForward={goingForward}
        handlePageTurn={handlePageTurn}
      >
        <Cover
          state={stateData}
          stateAbbr={stateAbbr}
          currentPage={currentPage}
        />
        <About1 />
        <About2 />
        <GainingMomentum1
          hoveredCity={hoveredCity}
          setHoveredCity={setHoveredCity}
        />
        <GainingMomentum2 currentPage={currentPage} hoveredCity={hoveredCity} />
        <WeAreMomentum1 currentPage={currentPage} />
        <WeAreMomentum2 />
        <CleanVsDirty1 quotes={stateData.energy} currentPage={currentPage} />
        <CleanVsDirty2 />
        <BenefitsOfSolar />
        <MaximizeSolarOffset />
        <CustomizedSystemDesign />
        <WeMakeSolarBeautiful />
        <FinestEquipment1 currentPage={currentPage} />
        <FinestEquipment2 currentPage={currentPage} />
        <SystemUpgrades1 />
        <SystemUpgrades2 currentPage={currentPage} />
        <ReferralProgram />
        <WhiteGloveService />
        <Installs
          installData={stateData.installs[0]}
          state={stateAbbr}
          usePageHeader={true}
          setCustomerQuote={setCustomerQuote}
          setShowingModal={setShowingModal}
        />
        <Installs
          installData={stateData.installs[1]}
          state={stateAbbr}
          setCustomerQuote={setCustomerQuote}
          setShowingModal={setShowingModal}
        />
        <Installs
          installData={stateData.installs[2]}
          state={stateAbbr}
          setCustomerQuote={setCustomerQuote}
          setShowingModal={setShowingModal}
        />
        <Installs
          installData={stateData.installs[3]}
          state={stateAbbr}
          setCustomerQuote={setCustomerQuote}
          setShowingModal={setShowingModal}
        />
        <MapPage
          handlePageTurn={handlePageTurn}
          setFullscreenMapIsOpen={setFullscreenMapIsOpen}
          stateData={stateData}
          userLocation={userLocation}
          currentPage={currentPage}
          pinsRef={pinsRef}
          setOnPinsUpdated={(fn) => {
            onPinsUpdatedRef.current = fn;
          }}
          stateAbbr={stateAbbr}
        />
        <BackCover currentPage={currentPage} />
      </Book>
    </>
  );
}

export default Lookbook;
