import React, { useState } from "react";

import { ReactComponent as PinSVG } from "../assets/Pin.svg";
import { ReactComponent as BluePinSVG } from "../assets/BluePin.svg";

import { motion } from "framer-motion";

const pinPositions = [
  { x: "1.4", y: "44.5", label: "San Francisco" },
  { x: "7.1", y: "57", label: "Orange" },
  { x: "8.7", y: "60.8", label: "San Diego" },
  { x: "14.3", y: "51.5", label: "Las Vegas" },
  { x: "19.3", y: "62.3", label: "Phoenix" },
  { x: "29.6", y: "67.4", label: "El Paso" },
  { x: "45.6", y: "75.3", label: "San Antonio" },
  { x: "47", y: "72.9", label: "Austin" },
  { x: "50", y: "65.7", label: "Dallas" },
  { x: "52.4", y: "74.7", label: "Houston" },
  { x: "74", y: "61.7", label: "Atlanta" },
  { x: "79.8", y: "76.5", label: "Tampa" },
  { x: "80.5", y: "70.6", label: "Jacksonville" },
  { x: "81.5", y: "79.7", label: "Fort Myers" },
  { x: "82.4", y: "75.2", label: "Orlando" },
  { x: "84.3", y: "78.5", label: "Port St Lucie" },
  { x: "85.2", y: "81.2", label: "Fort Lauderdale" },
  { x: "86.9", y: "41.4", label: "Lancaster" },
  { x: "89.2", y: "41.8", label: "Cherry Hill" },
  { x: "89.7", y: "39.7", label: "Metuchen" },
  { x: "89.8", y: "37.3", label: "South Plainfield" },
  { x: "90.2", y: "39.3", label: "Staten Island" },
  { x: "90.6", y: "40", label: "Wall" },
  { x: "91.2", y: "37.4", label: "Stamford" },
  { x: "91.3", y: "38.5", label: "Plainview" },
  { x: "92.4", y: "36", label: "East Berlin" },
  { x: "94.2", y: "33.2", label: "Malborough" },
];

const labelVariants = {
  hidden: { opacity: 0, x: -10, width: "0%" },
  visible: { opacity: 1, x: 0, width: "auto" },
};

const lineVariants = {
  hidden: { width: "0%" },
  visible: { width: "100%" },
};

const pinVariants = {
  hovered: { scale: 1.5, y: "-4px", x: "2px" },
  notHovered: { scale: 1 },
};

function Pin({ x = 0, y = 0, delay = 0, shouldShowLabel, label }) {
  const [isHoveredOver, setIsHoveredOver] = useState(false);
  const isHeadquarters = label === "South Plainfield";
  return (
    <motion.div
      initial={{ y: "-200px", x: "50px", opacity: 0 }}
      animate={{ y: "0px", x: "0px", opacity: 1 }}
      transition={{ duration: 1, delay, ease: "easeIn" }}
      style={{
        position: "absolute",
        top: `calc(${y + "%"} - 21px)`,
        left: x + "%",
        cursor: "default",
        zIndex: isHeadquarters ? Math.floor(y * 11) : Math.floor(y * 10),
      }}
    >
      <motion.div
        variants={pinVariants}
        initial="notHovered"
        animate={isHoveredOver || shouldShowLabel ? "hovered" : "notHovered"}
        onMouseEnter={() => setIsHoveredOver(true)}
        onMouseLeave={() => setIsHoveredOver(false)}
        style={{ width: "10px", height: "27px" }}
      >
        {isHeadquarters ? (
          <BluePinSVG
            width="150%"
            height="150%"
            style={{ aspectRatio: 0.494 }}
          />
        ) : (
          <PinSVG width="100%" height="100%" style={{ aspectRatio: 0.494 }} />
        )}
      </motion.div>

      <motion.div
        variants={labelVariants}
        initial="hidden"
        animate={isHoveredOver || shouldShowLabel ? "visible" : "hidden"}
        transition={{ ease: "easeOut" }}
        style={styles.pinLabelWrapper}
      >
        {label}
        <motion.div
          variants={lineVariants}
          initial="hidden"
          animate={isHoveredOver || shouldShowLabel ? "visible" : "hidden"}
          transition={{ ease: "easeOut", delay: 0.1 }}
          style={styles.underline}
        />
      </motion.div>
    </motion.div>
  );
}

function AnimatedPins({ style = {}, hoveredCity }) {
  const renderPins = () => {
    return pinPositions.map(({ x, y, label }, i) => (
      <Pin
        x={x}
        y={y}
        delay={(i + 1) ** 0.5 - 1}
        key={i}
        label={label}
        shouldShowLabel={hoveredCity === label}
      />
    ));
  };

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        backfaceVisibility: "hidden",
        ...style,
      }}
    >
      {renderPins()}
    </div>
  );
}

export default AnimatedPins;

const styles = {
  pinLabelWrapper: {
    position: "absolute",
    bottom: "133%",
    whiteSpace: "nowrap",
    color: "#2b2e34",
    left: "-15px",
    textShadow: "2px 2px 5px #fff",
    pointerEvents: "none",
  },
  underline: {
    height: 2,
    background: "-webkit-linear-gradient(45deg,  #336699, #33ccff)",
    width: "100%",
  },
};
