import React from "react";

import { motion } from "framer-motion";

import { ReactComponent as MSLogoTransparent } from "../../assets/MSLogoTransparent.svg";
import { ReactComponent as MSLogo2 } from "../../assets/MSLogo2.svg";

const textVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0 },
};

const transition = {
  duration: 1,
  delay: 0.5,
  ease: "easeInOut",
};

export default function BackCover({ currentPage }) {
  return (
    <div className="pageWrapper" style={styles.background}>
      <MSLogoTransparent width="100%" style={styles.bgLogo} />

      <motion.h6
        initial={"hidden"}
        animate={currentPage >= 12 ? "visible" : "hidden"}
        variants={textVariants}
        transition={transition}
        style={styles.thankYou}
      >
        Thank You!
      </motion.h6>
      <MSLogo2 width="10rem" style={styles.msLogo} />
      <p style={styles.footnote}>
        Copyright © 2022 Momentum Solar All Rights Reserved. D|B|A Momentum
        Solar *Subject to applicable terms and conditions. Eligibility and
        savings is determined by municipal utility, roof condition and roof
        space, azimuth, tree location, shading and other factors. HIC # – CA:
        1026366; CT: HIC.0646323; FL: CVC57036; MA: 192204; NJ: 13VH05539000;
        NYC: 2042828-DCA; PA: PA131435; TX: 31915; MA: 192204; AZ: 237781. EL
        License # - CA: 1026366; CO: ME.3000298; CT: HIC.0646323; DE:
        T100006310; FL: EC13008217; IL: SE7545; MA: ME: 10024-ELMR; ME:
        MS60021311; NH: 14277M: NJ: 34EB01591300; NYC: 12943; RI: B-014803; TX:
        409620; UT: 11242273-5502; VA: 2710069151; VT: EM-06808
      </p>
    </div>
  );
}

const styles = {
  background: {
    background: "-webkit-linear-gradient(45deg, #336699, #33ccff)",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    maxHeight: "100%",
  },
  bgLogo: {
    position: "absolute",
    height: "100%",
  },
  thankYou: {
    color: "#fff",
    fontSize:
      window.innerWidth > 1300
        ? "9rem"
        : window.innerWidth > 900
        ? "8rem"
        : "4rem",
    textAlign: "center",
    marginTop: window.innerWidth > 1200 ? "20%" : "5rem",
    fontFamily: "Collection",
  },
  msLogo: {
    marginTop: "10%",
  },
  footnote: {
    margin: window.innerWidth > 900 ? "1em 5em" : "1em",
    fontSize: window.innerWidth > 900 ? "9px" : "8px",
    color: "#fff",
    textAlign: "center",
  },
};
