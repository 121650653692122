import React from "react";

import { ReactComponent as MSLogoText } from "../../assets/MSLogoText.svg";

import CoverImage1 from "../../assets/covers/Cover1.png";
import CoverImage2 from "../../assets/covers/Cover2.png";
import CoverImage3 from "../../assets/covers/Cover3.png";
import CoverImage4 from "../../assets/covers/Cover4.png";
import AnimatedLogo1 from "../../Components/AnimatedLogo1";

import { motion } from "framer-motion";

const MSVariants = {
  noState: { opacity: 0, x: 50 },
  hasState: { opacity: 1, x: 0 },
};

const subTitleVariants = {
  noState: { opacity: 0, y: 50 },
  hasState: { opacity: 1, y: 0 },
};

const getCoverImage = (stateAbbr) => {
  switch (stateAbbr) {
    default:
      return CoverImage1;
    case "FL":
      return CoverImage2;
    case "TX":
      return CoverImage3;
    case "AZ":
    case "NV":
    case "CA":
      return CoverImage4;
  }
};

function Cover({ state, stateAbbr, currentPage }) {
  const CoverImage = getCoverImage(stateAbbr);

  return (
    <div className="pageWrapper" style={styles.background}>
      {currentPage === 0 && (isNaN(state) || !!state) && (
        <div style={styles.content}>
          <div style={styles.logoWrapper}>
            <AnimatedLogo1 />
            <motion.div
              variants={MSVariants}
              initial={"noState"}
              animate={stateAbbr ? "hasState" : "noState"}
              transition={{ duration: 1, delay: 1.5, easing: "easeOut" }}
              style={{ width: "70%" }}
            >
              <MSLogoText width="100%" />
            </motion.div>
          </div>
          <div style={styles.subTitleWrapper}>
            {stateAbbr && stateAbbr !== "Other" && (
              <motion.h3
                variants={subTitleVariants}
                initial={"noState"}
                animate={stateAbbr ? "hasState" : "noState"}
                transition={{ duration: 1, delay: 1.5, easing: "easeOut" }}
                style={styles.stateName}
              >
                {state.fullName}
              </motion.h3>
            )}
            <motion.h2
              variants={subTitleVariants}
              initial={"noState"}
              animate={stateAbbr ? "hasState" : "noState"}
              transition={{ duration: 1, delay: 1.7, easing: "easeOut" }}
              style={styles.subTitle}
            >
              Lookbook
            </motion.h2>
          </div>
        </div>
      )}
      <img src={CoverImage} style={styles.img} alt="Momentum Solar Home" />
    </div>
  );
}

export default Cover;

const styles = {
  background: {
    background: "linear-gradient(#E8F9FF, #fff)",
    alignItems: "flex-end",
    position: "relative",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    zIndex: 10,
    marginTop: window.innerWidth > 900 ? "1rem" : "0.5em",
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "0 2em",
  },
  subTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginRight: "2em",
    transform: `translate(0, ${window.innerWidth > 900 ? -50 : -20}px)`,
  },
  stateName: {
    fontSize: window.innerWidth > 900 ? "clamp(16px, 1.5rem, 42px)" : 14,
    color: "#2072AF",
  },
  subTitle: {
    fontFamily: "GothamBook",
    textTransform: "uppercase",
    fontSize: window.innerWidth > 900 ? "clamp(14px, 1.2rem, 30px)" : 12,
  },
  img: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    objectFit: "cover",
  },
};
