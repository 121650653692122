import React from "react";

import Background from "../../assets/CustomizedDesignBG.png";

function CustomizedSystemDesign() {
  return (
    <div className="pageWrapper">
      <div className="pageContent" style={styles.content}>
        <div className="card">
          <div>
            <h2>Customized</h2>
            <h1>system design</h1>
          </div>
          <p style={styles.text}>
            At Momentum Solar we custom design and engineer every system to
            maximize solar output, solar savings, and aesthetic appeal.
          </p>
          <p style={styles.text}>
            Our competitors employ a cookie-cutter, assembly line approach to
            solar system design and engineering.
          </p>
        </div>
      </div>
      <img src={Background} alt="Solar Offset Background" style={styles.img} />
    </div>
  );
}

export default CustomizedSystemDesign;

const styles = {
  content: {
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    background: "white",
    maxWidth: window.innerWidth > 800 ? "50%" : "40%",
    marginTop: "0px",
  },
  text: {
    marginTop: "1em",
  },
  img: {
    position: "absolute",
    height: "100%",
    zIndex: 0,
    right: 0,
  },
};
