import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useState } from "react";

import statesData from "../../Utils/states";
import ModalBackground from "./ModalBackground";

const states = Object.keys(statesData).map((key) => ({
  abbr: key,
  fullName: statesData[key].fullName,
}));
states.push({ abbr: "Other", fullName: "Other State" });

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
      width: 250,
    },
  },
};

function WhichStateModal({ setStateAbbr, showModal = true }) {
  const [selectedState, setSelectedState] = useState();

  const renderStates = () => {
    return states.map((state) => {
      return (
        <MenuItem style={{ zIndex: 3000 }} value={state} key={state.abbr}>
          {state.fullName}
        </MenuItem>
      );
    });
  };

  const getButtonStyle = () => {
    return !selectedState
      ? { ...styles.button, ...styles.disabled }
      : styles.button;
  };

  return (
    <>
      Select Your State
      <FormControl style={styles.input}>
        <InputLabel id="select-label">State</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          onChange={(e) => setSelectedState(e.target.value.abbr)}
          value={selectedState}
          input={<OutlinedInput label="State" />}
          MenuProps={MenuProps}
        >
          {renderStates()}
        </Select>
      </FormControl>
      <button
        style={getButtonStyle()}
        disabled={!selectedState}
        onClick={() => setStateAbbr(selectedState)}
      >
        Continue
      </button>
    </>
  );
}

export default WhichStateModal;

const styles = {
  button: {
    border: "none",
    borderRadius: 6,
    padding: "1em 2em",
    background: "#33ccff",
    fontWeight: "bold",
    color: "white",
  },
  disabled: { background: "#DDDDDD", color: "grey" },
  buttonsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  input: {
    fontFamily: "Gotham",
    width: "200px",
    margin: "1em 0",
  },
};
