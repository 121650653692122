import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as MSLogo } from "../../assets/MSLogo.svg";
import { ReactComponent as ChevronLeft } from "../../assets/ChevronLeft.svg";
import { ReactComponent as ChevronRight } from "../../assets/ChevronRight.svg";
import { ReactComponent as MapIcon } from "../../assets/MapIcon.svg";

import { motion } from "framer-motion";
import MenuIcon from "./MenuIcon";

function CollapsibleMenu({
  children,
  handlePageTurn,
  showingModal,
  setFullscreenMapIsOpen,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  let timeoutRef = useRef();

  useEffect(() => {
    if (window.innerWidth < 900) {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      if (!isOpen) {
        timeoutRef.current = setTimeout(() => {
          setIsVisible(false);
        }, 5000);
      }
    }
  }, [handlePageTurn, isOpen, isVisible]);

  const openMenuVariants = {
    closed: {
      maxHeight: "0%",
      height: "0px",
      width: "0px",
      opacity: 0,
    },
    open: {
      maxHeight: "calc(100vh - 3em)",
      height: "100%",
      width: "256px",
      opacity: 1,
    },
  };

  const menuButtonVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 48 },
  };

  const sliderVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: 24 },
  };

  const sliderTransition = { duration: 0.2, ease: "linear" };

  const openMenuTransition = {
    duration: 0.8,
    ease: "easeInOut",
  };

  return (
    <div style={styles.wrapper}>
      <motion.div
        variants={sliderVariants}
        animate={isVisible ? "hidden" : "visible"}
        initial="hidden"
        transition={sliderTransition}
        style={styles.dotWrapper}
        onClick={() => !isVisible && setIsVisible(true)}
      >
        <div style={styles.dot} />
        <div style={styles.dot} />
        <div style={styles.dot} />
      </motion.div>

      <motion.div
        variants={menuButtonVariants}
        animate={isVisible ? "visible" : "hidden"}
        transition={sliderTransition}
        style={{
          height: 48,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxHeight: "100vh",
        }}
      >
        <MSLogo
          height="32"
          width="32"
          style={{ margin: "0 16px", height: 32, width: 32 }}
          onClick={() => {
            isVisible && setIsVisible(false);
            setIsOpen(false);
          }}
        />
        <button
          style={styles.button}
          onClick={() => setFullscreenMapIsOpen(true)}
        >
          <MapIcon style={{ color: "#a4a4a4" }} />
        </button>
        <button
          onClick={() => handlePageTurn(-1)}
          style={styles.button}
          disabled={showingModal}
        >
          <ChevronLeft style={{ color: "#A5A5A5" }} />
        </button>
        <button
          onClick={() => handlePageTurn(1)}
          style={styles.button}
          disabled={showingModal}
        >
          <ChevronRight style={{ color: "#A5A5A5" }} />
        </button>

        <button onClick={() => setIsOpen(!isOpen)} style={styles.button}>
          <MenuIcon isOpen={isOpen} />
        </button>
      </motion.div>
      <motion.div
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={openMenuVariants}
        transition={openMenuTransition}
        style={styles.menu}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default CollapsibleMenu;

const styles = {
  wrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    background: "#fff",
    zIndex: 10000,
    boxShadow: "3px 3px 10px #0001",
    minWidth: 256,
  },
  menu: {
    maxHeight: "calc(100vh - 3em)",
    overflow: "auto",
  },
  button: {
    background: "transparent",
    aspectRatio: 1,
    border: "none",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  dot: {
    background: "lightgrey",
    height: 8,
    width: 8,
    borderRadius: 10,
    margin: "0 2px",
  },
  dotWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "auto",
  },
};
