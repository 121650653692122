import React from "react";
import Layout1 from "./Layout1";
import Layout2 from "./Layout2";
import Layout3 from "./Layout3";

const layouts = [Layout1, Layout2, Layout3];

function Installs({
  installData,
  state,
  usePageHeader,
  setCustomerQuote,
  setShowingModal,
}) {
  const Layout = layouts[installData.layout - 1];
  return (
    <Layout
      installData={installData}
      state={state}
      usePageHeader={usePageHeader}
      setCustomerQuote={setCustomerQuote}
      setShowingModal={setShowingModal}
    />
  );
}

export default Installs;
