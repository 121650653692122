import FL1 from "../../assets/statePhotos/FL Install 1.png";
import FL2 from "../../assets/statePhotos/FL Install 2.png";
import FL3 from "../../assets/statePhotos/FL Install 3.png";
import FL4 from "../../assets/statePhotos/FL Install 4.png";

const FL = {
  coordinates: {
    center: { lat: 28.374555632861593, lng: -81.84229633251374 },
    zoom: 7,
  },
  fullName: "Florida",
  installs: [
    {
      photo: FL1,
      panels: "25 Q Cell 315W",
      inverters: "25 Enphase IQ-7",
      production: "12396",
      customer: {
        quote:
          "My wife and I loved the experience. The Momentum team fully explained the solar program and confidently addressed our concerns. With all their help, there was no reason for us not to pursue the installation of solar panels. Thanks for doing a great job.",
        name: "Jeffrey B.",
        location: "Port St. Lucie, FL",
        date: "April 2021",
      },
      layout: 3,
    },
    {
      photo: FL2,
      panels: "24 Q Cell 340W",
      inverters: "24 Enphase IQ-7",
      production: "11340",
      customer: {
        quote:
          "Momentum crew was friendly and very efficient while installing our solar panels on our home. They did the job in less than half a day. I would recommend momentum Solar to anyone interested in switching to solar.",
        name: "German E.",
        location: "Orlando, FL",
        date: "September 2021",
      },
      layout: 1,
    },
    {
      photo: FL3,
      panels: "48 Q Cell 340W",
      inverters: "48 Enphase IQ-7",
      production: "25888",
      customer: {
        quote:
          "Momentum Solar employees are so knowledgeable on all matters solar. This made me feel very confident about going with them when switching to solar. With their help, I learned a lot about renewable energy. I love my solar panels and they have been working flawlessly since I had them installed last year.",
        name: "Kelly M.",
        location: "Tamarac, FL",
        date: "January 2021",
      },
      layout: 1,
    },
    {
      photo: FL4,
      panels: "30 Q Cell 340W",
      inverters: "30 Enphase IQ-7",
      production: "15051",
      customer: {
        quote:
          "I love this company’s approach to customer service. They make solar as easy to understand as possible. Before I agreed to work with them, I knew very little about solar. Today, I am able to explain solar to all my friends with ease.",
        name: "Kanika J.",
        location: "Lutz, FL",
        date: "July 2021",
      },
      layout: 2,
    },
  ],
  energy: [
    {
      quote:
        "The 1,000 kWh-an-hour customer was already ontrack to see monthlybills increase by 18%through 2025.",
      source: "catalystmiami.org",
      href: "https://www.catalystmiami.org/",
    },
    {
      quote: "FPL has seen more than a 10 percent cost increase.",
      source: "wfla.com",
      href: "https://www.wfla.com/",
    },
    {
      quote:
        "These rising prices have been compounded by extreme cold weather and increased demand throughout the country",
      source: "sun-sentinel.com",
      href: "https://www.sun-sentinel.com/",
    },
  ],
};

export default FL;
