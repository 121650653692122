import React from "react";
import LabelCard from "./LabelCard";

import Quote from "./Quote";
import Stats from "./Stats";

function Layout3({
  installData,
  usePageHeader,
  setCustomerQuote,
  setShowingModal,
}) {
  const { photo, customer, fullName } = installData;
  return (
    <div className="pageWrapper" style={styles.pageWrapper}>
      <img src={photo} alt={fullName + " Solar Home"} style={styles.img} />
      <div className="pageContent">
        {usePageHeader && (
          <div style={styles.labelWrapper}>
            <LabelCard />
          </div>
        )}
        <div style={styles.itemsWrapper}>
          <div
            style={{
              ...styles.item,
              maxHeight: window.innerWidth > 900 ? "35%" : "25%",
            }}
          >
            <Stats installData={installData} />
          </div>
          <div
            style={{
              ...styles.item,
              height: window.innerWidth > 900 ? "65%" : "75%",
            }}
          >
            <Quote
              customer={customer}
              setCustomerQuote={setCustomerQuote}
              setShowingModal={setShowingModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout3;
const styles = {
  pageWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  itemsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "100%",
  },
  item: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    height: "100%",
    width: "100%",
    bottom: 0,
    maxWidth: "50%",
    objectFit: "cover",
  },
  labelWrapper: {
    display: "flex",
    alignItems: "flex-end",
    position: "absolute",
    top: 0,
    left: 0,
    height: "20%",
    marginLeft: "1.5em",
    background: "white",
  },
};
