import React from "react";

import Background from "../../assets/FinestEquipmentBG.png";

function FinestEquipment2() {
  return (
    <div className="pageWrapper">
      <img
        src={Background}
        alt="Solar Meter"
        style={{
          position: "absolute",
          height: "100%",
          zIndex: 0,
          right: "-10%",
        }}
      />
    </div>
  );
}

export default FinestEquipment2;
