import React from "react";

import Inverter from "../../assets/Inverter.png";

import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0 },
};

const transition = {
  duration: 1,
  delay: 0.5,
  ease: "easeInOut",
};

function FinestEquipment1({ currentPage = 0 }) {
  return (
    <div className="pageWrapper" style={styles.background}>
      <div className="pageContent" style={styles.content}>
        <div style={styles.headerWrapper}>
          <h2 style={styles.text}>Industry finest</h2>
          <h1>equipment</h1>
        </div>
        <p
          style={{
            ...styles.text,
            fontSize: window.innerWidth > 900 ? "inherit" : "12px",
          }}
        >
          We strive to help you maximize your solar power and savings.
        </p>
        <div style={{ display: "flex" }}>
          <motion.img
            initial={"hidden"}
            animate={currentPage === 7 ? "visible" : "hidden"}
            variants={variants}
            transition={transition}
            src={Inverter}
            style={styles.img}
            alt="Inverter"
          />
          <div style={styles.inverterCard}>
            <motion.h5
              initial={"hidden"}
              animate={currentPage === 7 ? "visible" : "hidden"}
              variants={variants}
              transition={{ ...transition, delay: 0.7 }}
              style={styles.header}
            >
              Enphase IQ Inverters
            </motion.h5>
            <motion.p
              initial={"hidden"}
              animate={currentPage === 7 ? "visible" : "hidden"}
              variants={variants}
              transition={{ ...transition, delay: 0.8 }}
              style={{ ...styles.text, margin: "1em 0" }}
            >
              - Dramatically simplifies the installation process while achieving
              the highest system efficiency
            </motion.p>
            <motion.p
              initial={"hidden"}
              animate={currentPage === 7 ? "visible" : "hidden"}
              variants={variants}
              transition={{ ...transition, delay: 0.9 }}
              style={styles.text}
            >
              - IQ Series Microinverters extend the reliability standards set
              forth by previous generations and undergo over a million hours of
              power-on testing, enabling Enphase to provide an industry-leading
              warranty of up to 25 years
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinestEquipment1;

const styles = {
  background: {
    background: "-webkit-linear-gradient( #464646, #141414)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  headerWrapper: {
    marginBottom: "1em",
    alignSelf: "flex-start",
  },
  header: { color: "#33ccff" },
  inverterCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginBottom: "7em",
    marginLeft: "2em",
  },
  text: {
    color: "#fff",
    fontSize: "12px",
  },
  img: {
    width: "50%",
    justifySelf: "flex-end",
    marginBottom: "3em",
    objectFit: "contain",
  },
};
