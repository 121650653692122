import React from "react";

import AllAwards from "../../assets/awards/Awards.png";

import Angi from "../../assets/awards/Angi.png";
import ErnstAndYoung from "../../assets/awards/Ernst & Young.png";
import Fast500 from "../../assets/awards/Fast500.png";
import BBB from "../../assets/awards/BBB.png";
import Google from "../../assets/awards/Google.png";
import Inc5000 from "../../assets/awards/Inc5000.png";
import Glassdoor from "../../assets/awards/Glassdoor.png";
import AngiesList from "../../assets/awards/AngiesList.png";
import TopSolarContractor from "../../assets/awards/TopSolarContractor.png";
import Inc500 from "../../assets/awards/Inc500.png";
import SolarPowerWorld from "../../assets/awards/SolarPowerWorld.png";
import { motion } from "framer-motion";

const awardImgs = [
  Angi,
  ErnstAndYoung,
  Fast500,
  Google,
  BBB,
  Inc5000,
  Glassdoor,
  AngiesList,
  Inc500,
  TopSolarContractor,
  SolarPowerWorld,
];
const awardImgs1 = [Angi, ErnstAndYoung, Fast500, Google, BBB, Inc5000];

const awardImgs2 = [
  Glassdoor,
  AngiesList,
  Inc500,
  TopSolarContractor,
  SolarPowerWorld,
];

const variants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

function WeAreMomentum1({ currentPage }) {
  // const renderAwards = (images) => {
  //   return images.map((awardImg, i) => {
  //     return (
  //       <>
  //         <motion.img
  //           initial={"hidden"}
  //           animate={currentPage === 3 ? "visible" : "hidden"}
  //           variants={variants}
  //           transition={{
  //             duration: 1,
  //             delay: 0.5 + 0.1 * i,
  //             ease: "easeOut",
  //           }}
  //           src={awardImg}
  //           style={styles.image}
  //           alt="Award"
  //           key={i}
  //         />
  //       </>
  //     );
  //   });
  // };

  return (
    <div className="pageWrapper">
      <div className="pageContent" style={styles.content}>
        <div style={{ alignSelf: "flex-start" }}>
          <h2>We are</h2>
          <h1>momentum</h1>
        </div>
        <div>
          <h3 style={styles.header}>100% Focused on Customer Experience</h3>
          <p>
            With dedicated teams to walk you through the entire solar
            installation process
          </p>
          <h3 style={styles.header}>Solar Industry Leader Since 2009</h3>
          <p>
            With operations in New Jersey, New York, Massachusetts, Connecticut,
            California, Florida, Texas, Arizona, Nevada, Georgia and
            Pennsylvania
          </p>
          <h3 style={styles.header}>An Owner-Operated Company</h3>
          <p>
            Committed to saving you money with premium equipment and a
            customized system design to maximize solar output
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {/* <div style={styles.imageWrapper}>{renderAwards(awardImgs1)}</div>
          <div style={styles.imageWrapper}>{renderAwards(awardImgs2)}</div> */}
          <motion.img
            initial={"hidden"}
            animate={currentPage === 3 ? "visible" : "hidden"}
            variants={variants}
            transition={{
              duration: 2,
              delay: 1,
              ease: "easeOut",
            }}
            style={{ width: "100%" }}
            src={AllAwards}
            alt="The awards Momentum Solar has been awarded"
          />
        </div>
      </div>
    </div>
  );
}

export default WeAreMomentum1;

const styles = {
  content: {
    flexDirection: "column",
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  header: {
    marginTop: "1em",
  },
  imageWrapper: {
    marginTop: "1em",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-around",
    maxHeight: "100%",
  },
  image: {
    maxHeight:
      window.innerWidth > 1439 && window.innerHeight > 640
        ? "4rem"
        : window.innerWidth > 1200 && window.innerHeight > 590
        ? "3rem"
        : "2rem",
    marginBottom: "1em",
    marginRight: "0.5em",
  },
};
